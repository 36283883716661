(function () {
	'use strict';

	angular.module('dcApp').controller('PublicationController', [
		'$scope',
		'$stateParams',
		'PublicationService',
		'DashboardService',
		'DateService',
		'$rootScope',
		'LANGUAGES',
		function ($scope, $stateParams, PublicationService, DashboardService, DateService, $rootScope, LANGUAGES) {
			var loadDashboard = function (
				centerings,
				callbackFunction,
				applyInitialCenterings
			) {
				if (/^fr\b/.test(navigator.language)) {
					$rootScope.setDefaultLanguage(LANGUAGES.FRENCH);
				} else {
					$rootScope.setDefaultLanguage(LANGUAGES.ENGLISH);
				}
				$scope.isLoading = true;
				let formattedCentering = angular.copy(centerings);
				formattedCentering.forEach(centering => DashboardService.centeringDateToISO(centering));
				PublicationService.getDashboardData(
					$stateParams.link,
					formattedCentering,
					applyInitialCenterings
				).then(function (response) {
					$scope.publication_date = $rootScope.getDateTimeWithPatternMn(
						response.data.publication_date
					);
					$scope.isLoading = false;

					if (callbackFunction) {
						callbackFunction(response.data);
						return;
					}
					// if publication date check if centering interval and get it
					if (response.data.publication_date
						&& response.data.config.config
						&& response.data.config.config.global
						&& response.data.config.config.global.centerings
						&& response.data.config.config.global.centerings.length > 0) {
						let isoPublicationDate = DateService.dateToISOString(response.data.publication_date);
						response.data.config.config.global.centerings.forEach((centering) => {
							if (centering.slider_date_min && !centering.slider_date_max) {
								centering.slider_date_max = isoPublicationDate;
								if (centering.value) {
									if (centering.value.without_time) {
										centering.value.between_date_without_time_value2 = isoPublicationDate;
									} else {
										centering.value.between_date_value2 = isoPublicationDate;
									}
								}
							}
						});
					}
					$scope.label = response.data.publication_label;
					$scope.headerVisible = response.data.header_visible;
					let fullData = response.data;
					fullData.link = $stateParams.link;
					$scope.dashboardView = {
						full_data: fullData,
						reloadFullData: loadDashboardWithCentering,
					};
				});
			};

			loadDashboard([], undefined, true);

			var loadDashboardWithCentering = function (centerings, callbackFunction) {
				loadDashboard(centerings, callbackFunction, false);
			};
		},
	]);
})();
