'use strict';

angular
	.module('dcApp')
	.directive('formule', [
		'$parse',
		function ($parse) {
			var controller = [
				'$log',
				'$scope',
				'$rootScope',
				'$http',
				'$window',
				'DataBlocksService',
				'$sce',
				'toaster',
				'$timeout',
				'$filter',
				'gettextCatalog',
				'API_BASE_URL_BACKEND',
				'FormulaCatalogService',
				'PAGINATIONS_SIZE',
				'PAGINATIONS_SIZES',
				'GrammarUtils',
				function (
					$log,
					$scope,
					$rootScope,
					$http,
					$window,
					DataBlocksService,
					$sce,
					toaster,
					$timeout,
					$filter,
					gettextCatalog,
					API_BASE_URL_BACKEND,
					FormulaCatalogService,
					PAGINATIONS_SIZE,
					PAGINATIONS_SIZES,
					GrammarUtils
				) {
					let vm = this;
					const SCOPE_ID = $scope.$id;
					const FORMULA_SUGGESTIONS = '#formulaSuggestions-' + SCOPE_ID;
					const FORMULA_TEXT_AREA = '#formulaTextArea-' + SCOPE_ID;
					const FORMULA_SUGGESTIONS_SEARCH =
						'#suggestionsSearchInput-' + SCOPE_ID;
					const FORMULA_LABEL = '#formulaLabel-' + SCOPE_ID;
					const KEYBOARD_SHORTCUTS_LINK = '#shortcutLink-' + SCOPE_ID;
					const FORMULA_EDITOR = '#formuleEditeur-' + SCOPE_ID;

					let unsupportedAction = gettextCatalog.getString('Action impossible');
					let incompatibleType = gettextCatalog.getString('Type incompatible');
					let ruleTra = gettextCatalog.getString('Règle');
					let ruleDefTra = gettextCatalog.getString('rule.definition');
					const MIN_ARGS = 1;
					const MAX_ARGS = 15;
					let selectedElms = [];
					let jsonResponse = [];
					let updatedFormulaActive = true;
					let parentFunction;

					const translateMap = {
						Actions: gettextCatalog.getString('Actions'),
						Partagé: gettextCatalog.getString('Partagé'),
						Utilisateur: gettextCatalog.getString('Utilisateur'),
						'Crée le': gettextCatalog.getString('Crée le'),
						Type: gettextCatalog.getString('Type'),
						Description: gettextCatalog.getString('Description'),
						Tag: gettextCatalog.getString('Tag'),
						Libellé: gettextCatalog.getString('Libellé'),
					};

					$scope.SCOPE_ID = angular.copy(SCOPE_ID);

					$scope.noFormula = gettextCatalog.getString(
						'Pas de Formule à copier'
					);
					$scope.copyFormula = gettextCatalog.getString(
						'Copier la Formule actuelle'
					);
					$scope.noPaste = gettextCatalog.getString('Pas de Formule à coller');
					$scope.pasteFormula = gettextCatalog.getString(
						'Coller la Formule copiée'
					);
					//Help
					$scope.helpTitleTra = gettextCatalog.getString('Aide Formules');
					$scope.elements = [];
					$scope.formulaDisplay = {};
					$scope.elements[0] = {};
					$scope.enumList = {};
					$scope.isEnum = false;
					$scope.dataGroup = 'Données';
					$scope.functionsGroup = 'Formules de base';
					$scope.contsGroup = 'Constantes';
					$scope.valuesGroup = 'value';
					$scope.elements[0].group = $scope.valuesGroup;
					$scope.formulaRoot = null;
					$scope.argsNumber = 1;
					$scope.countMyFormulas = 0;
					$scope.sortableOptions = {};
					$scope.hide_advanced = true;
					$scope.showHideElmfLags = {};
					$scope.impact = 0;
					$scope.data = {};
					$scope.isDuplicateMode = false;
					$scope.typesMap = {
						string: 'String',
						decimal: 'Décimal',
						date: 'Date',
						integer: 'Integer',
						big_integer: 'Big integer',
						list: 'Liste',
						boolean: 'Booléen',
						geometry: 'Géométrie',
						words: 'Mots',
					};
					$scope.sortableOptions = {
						// Maybe we should use $watch on the model to update only if changed
						stop: function () {
							replaceParam(
								$scope.formulaRoot,
								$scope.concernedParam.id,
								$scope.concernedParam
							);
							buildFormulaObject(true);
						},
					};
					$scope.flags = {};
					$scope.formulaCatalogCount = 0;
					$scope.existFormule = false;
					$scope.showFormule = true;
					$scope.showTreeMode = false;
					$scope.currentUser = $rootScope.account.login;
					$scope.currentUserId = $rootScope.account.userId;
					$scope.eventAlreadyBind = false;
					$scope.firstFunction = true;
					$scope.isDateToStrEnums = false;
					$scope.focusIndex = 0;
					$scope.suggSearchOn = false;
					$scope.labelInputFocused = false;
					$scope.descInputFocused = false;
					$scope.metadataConfig = {
						htmlId: 'formulaCat',
						hideActive: true,
						hideLicence: true,
					};

					$scope.tagBox = {
						displayExpr: 'code',
						valueExpr: 'id',
						searchEnabled: true,
						editEnabled: true,
						tagTemplate: 'tagTemplate',
					};

					$scope.formulaCatalogElements = [];
					$scope.atLeastOneSelected = false;
					$scope.keys = [];
					$scope.keys.push({
						code: 13,
						action: function () {
							$scope.insertSelectedElement($scope.focusIndex);
						},
					});
					$scope.keys.push({
						code: 38,
						action: function () {
							if (!$scope.elementLists) {
								return;
							}
							$scope.focusIndex--;
							if ($scope.focusIndex < 0) {
								$scope.focusIndex = 0;
							}
							scrollTo();
						},
					});
					$scope.keys.push({
						code: 40,
						action: function () {
							if (!$scope.elementLists) {
								return;
							}
							$scope.focusIndex++;
							scrollTo();
							if ($scope.focusIndex >= $scope.elementLists.length) {
								$scope.focusIndex = $scope.elementLists.length - 1;
							}
						},
					});
					$scope.keys.push({
						code: 37,
						action: function () {
							moveTo('left');
						},
					});
					$scope.keys.push({
						code: 9,
						action: function () {
							focusSwitch();
						},
					});
					$scope.keys.push({
						code: 39,
						action: function () {
							moveTo('right');
						},
					});

					var lookupTagDataSource = {
						store: new DevExpress.data.CustomStore({
							loadMode: 'raw',
							load: function () {
								return getAvailableTags().then(function (response) {
									return response;
								});
							},
						}),
					};

					$scope.dataGridOptions = {
						paging: {
							enabled: true,
							pageSize: PAGINATIONS_SIZE,
						},
						pager: {
							showPageSizeSelector: true,
							allowedPageSizes: PAGINATIONS_SIZES,
							showInfo: true,
							visible: true,
						},
						filterRow: {
							visible: true,
							applyFilter: 'auto',
						},
						selection: {
							mode: 'multiple',
							showCheckBoxesMode: 'always',
						},
						searchPanel: {
							visible: true,
							width: 240,
						},
						rowAlternationEnabled: true,
						headerFilter: {
							visible: true,
							applyFilter: 'auto',
						},
						showColumnLines: true,
						showRowLines: true,
						allowColumnReordering: true,
						allowColumnResizing: true,
						columnAutoWidth: true,
						showBorders: true,
						columnFixing: {
							enabled: true,
						},
						columns: formulaCatalogListCols(translateMap, lookupTagDataSource),
						onInitialized: function (e) {
							$scope.gridFormulaCatInstance = e.component;
						},
						onSelectionChanged: function (selectedItems) {
							if (!selectedItems.selectedRowsData) {
								return;
							}

							let selectedRowsElms = _.uniq(selectedItems.selectedRowsData);
							if (selectedRowsElms.length > 0) {
								let selectedItem =
									selectedRowsElms[selectedRowsElms.length - 1];
								if (selectedItem && selectedItem.formula) {
									selectedItem.formula_str = buildFormulaDisplayed(
										selectedItem.formula,
										$scope.caracMode,
										ruleTra,
										$scope.selectedWidget.grammar.columns,
										$scope.elements
									);
								}
								$scope.selectedFormulaCat = selectedItem;
							}
							selectedElms = _.map(selectedRowsElms, function (elm) {
								return elm.id;
							});
							$scope.atLeastOneSelected = selectedRowsElms.length > 0;
						},
						onToolbarPreparing: function (e) {
							let dataGrid = e.component;
							let filterByUserDefaultValue = true;
							e.toolbarOptions.items.unshift(
								{
									location: 'before',
									widget: 'dxCheckBox',
									options: {
										text: gettextCatalog.getString('Uniquement mes Formules'),
										value: filterByUserDefaultValue,
										onValueChanged: function (ev) {
											if (ev.value) {
												dataGrid.filter([
													'created_by',
													'=',
													$scope.currentUserId,
												]);
											} else {
												dataGrid.filter(null);
											}
										},
									},
								},
								{
									location: 'after',
									widget: 'dxButton',

									options: {
										icon: 'far fa-upload fa-lg',
										onClick: function () {
											$scope.showExportFormulaPopupMultiple();
										},
										bindingOptions: {
											visible: 'atLeastOneSelected',
										},
									},
								}
							);
							// filter for the first time if true
							if (filterByUserDefaultValue) {
								dataGrid.filter(['created_by', '=', $scope.currentUserId]);
							}
						},
					};

					$scope.clipboard = undefined;
					$scope.clipboardPopoverOptions = {
						target: '#clipboardPopoverL-' + SCOPE_ID,
						showEvent: 'mouseenter',
						hideEvent: 'mouseleave',
						position: 'top',
						maxWidth: '50%',
						visible: false
					}

					$scope.keyboardShortcutsPopoverOptions = {
						target: KEYBOARD_SHORTCUTS_LINK,
						showEvent: 'dxclick',
						title: gettextCatalog.getString('Raccourcis clavier'),
						showTitle: true,
						width: 450,
						position: 'top',
					};

					$scope.visibleExportPopup = false;
					$scope.exportPopupOptions = {
						width: 500,
						height: 170,
						shading: false,
						contentTemplate: 'exportFormulaTemplate',
						showTitle: true,
						dragEnabled: false,
						closeOnOutsideClick: true,
						showCloseButton: true,
						bindingOptions: {
							visible: 'visibleExportPopup',
							title: 'exportPopupOptionsTitle',
						},
					};

					let formulaToExport;
					$scope.exportFormulaFileName = '';
					$scope.exportFormula = { data: {} };

					// Get list of formulas
					DataBlocksService.getFormules().then(function (result) {
						jsonResponse = result;
						$window.dcFormulasByIdentifer = generateMapByIdentifier(
							result.formule
						);
						$scope.dcFormulasByIdentifer = $window.dcFormulasByIdentifer;
						$scope.elements = getFormulasAndConstantsList();
					});

					$scope.getTypeLabel = function (type, isList) {
						if (type in $scope.typesMap) {
							return $sce.trustAsHtml(getHtmlIconByType(type, isList, GrammarUtils.getTypeLabel(type)));
						} else {
							return type;
						}
					};

					// Count my formulas
					vm.countMyFormulasOp = function () {
						$scope.countMyFormulas = 0;
						for (
							var e = 0;
							e < $scope.selectedWidget.grammar.columnsTemp.length;
							e++
						) {
							if (
								$scope.selectedWidget.grammar.columnsTemp[e].formula !=
									undefined &&
								($scope.selectedWidget.grammar.columnsTemp[e].formula
									.semantic_order == 1 ||
									$scope.selectedWidget.grammar.columnsTemp[e].formula
										.semantic_order == undefined)
							) {
								$scope.countMyFormulas = $scope.countMyFormulas + 1;
							}
						}
					};

					$scope.typedValueIsValid = false;
					let typedValueType = undefined;

					$scope.getTypeHtml = function () {
						if (typedValueType != undefined) {
							typedValueType = typedValueType.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(typedValueType, false));
						} else {
							return $sce.trustAsHtml(
								'<span class="formula-group-icon color-black" title="' +
									gettextCatalog.getString('Nouvelle valeur') +
									'"> d </span>'
							);
						}
					};

					// Filter displayed items in typeAhead list
					$scope.search = { value: '' };
					let valueInput = false;

					$scope.getElements = function (search, group) {
						$scope.elements[0].label = search;
						search = search !== undefined ? search : '';
						var filteredElements = [];
						if ($scope.wordsOnly) {
							filteredElements = _.filter($scope.elements, function (el) {
								return el.group === 'Données' && el.type === 'words';
							});
						} else if ($scope.getElemmentListTypeOnly) {
							filteredElements = _.filter($scope.elements, function (el) {
								return (
									el.group === group &&
									((el.response !== undefined && el.response.list) ||
										el.list ||
										(el.formula != undefined && el.formula.is_list)) &&
									((el.response !== undefined &&
										$scope.paramAcceptedTypes.indexOf(el.response.type) > -1) ||
										(el.list &&
											$scope.paramAcceptedTypes.indexOf(el.type) > -1) ||
										(el.formula != undefined &&
											$scope.paramAcceptedTypes.indexOf(el.formula.data_type) >
												-1))
								);
							});
						} else if ($scope.listFunction || $scope.rankingFunctionParams) {
							filteredElements = _.filter($scope.elements, function (el) {
								if ($scope.filteredType !== undefined) {
									el.type =
										el.response !== undefined
											? el.response.type
											: el.type !== undefined
											? el.type
											: undefined;
									var elIsList =
										(el != undefined &&
											el.response != undefined &&
											el.response.list) ||
										el.list;
									elIsList =
										(elIsList && $scope.isList) ||
										(!elIsList && !$scope.isList) ||
										$scope.isRootFunction;
									return (
										elIsList &&
										el.group === group &&
										el.label !== 'ARGS' &&
										!isListCreate(el.label) &&
										!el.list &&
										($scope.filteredType !== undefined
											? Array.isArray($scope.filteredType)
												? _.indexOf($scope.filteredType, el.type) > -1
												: el.type === $scope.filteredType
											: true)
									);
								} else {
									return (
										el.group === group &&
										el.label !== 'ARGS' &&
										!isListCreate(el.label) &&
										!el.list
									);
								}
							});
							if (
								$scope.filteredType !== undefined &&
								group === $scope.valuesGroup
							) {
								var queryObject = {
									label: search,
									group: $scope.valuesGroup,
									query: true,
								};
								filteredElements.unshift(queryObject);
							}
						} else {
							// filter by Group
							filteredElements = _.filter($scope.elements, function (el) {
								var elType =
									el.group == 'value'
										? 'ALL'
										: el != undefined &&
										  el.response != undefined &&
										  el.response.type != undefined
										? el.response.type
										: el != undefined && el.type != undefined
										? el.type
										: 'ALL';
								var elIsList =
									(el != undefined &&
										el.response != undefined &&
										el.response.list) ||
									el.list;
								elIsList =
									(elIsList && $scope.isList) ||
									(!elIsList && !$scope.isList) ||
									$scope.isRootFunction;
								return (
									el.group == group &&
									el.label != 'ARGS' &&
									($scope.listFunction ? !isListCreate(el.label) : true) &&
									elIsList &&
									($scope.paramAcceptedTypes == undefined ||
										($scope.paramAcceptedTypes.length == 0 &&
											(vm.returnType == undefined ||
												vm.returnType == elType)) ||
										elType === 'ALL' ||
										(elType != undefined &&
											$scope.paramAcceptedTypes.indexOf(elType) > -1))
								);
							});
						}

						// To filter on all the object values we can use angularJs default filter fileFilter (module need to injected) var varName = filterFilter($scope.elements, search);
						var filtered = _.filter(filteredElements, function (el) {
							if (el != undefined) {
								if (el.label != undefined && search != undefined) {
									return (
										el.label.toLowerCase().indexOf(search.toLowerCase()) > -1
									);
								} else {
									return false;
								}
							} else {
								return false;
							}
						});

						if (group == 'value') {
							valueInput = true;
							validateTypingValue(filtered);
						} else {
							valueInput = false;
						}

						return _.sortBy(filtered, function (el) {
							return el.label.toLowerCase().startsWith(search.toLowerCase())
								? -1
								: 1;
						});
					};

					// filter enums
					$scope.getEnums = function (searchValue) {
						var filteredEnums = [];
						if ($scope.enums !== undefined) {
							if ($scope.rankingFunctionParams || $scope.listFunction) {
								filteredEnums = _.filter($scope.enums, function (el) {
									if (el !== undefined) {
										if (el.label !== undefined) {
											if ($scope.filteredType !== undefined) {
												return (
													el.label
														.toLowerCase()
														.indexOf(searchValue.toLowerCase()) > -1 &&
													(Array.isArray($scope.filteredType)
														? _.indexOf($scope.filteredType, el.type) > -1
														: el.type === $scope.filteredType)
												);
											} else {
												return (
													el.label
														.toLowerCase()
														.indexOf(searchValue.toLowerCase()) > -1
												);
											}
										} else {
											return false;
										}
									} else {
										return false;
									}
								});
							} else {
								filteredEnums = _.filter($scope.enums, function (el) {
									if (el !== undefined) {
										if (el.label !== undefined) {
											return (
												el.label
													.toLowerCase()
													.indexOf(searchValue.toLowerCase()) > -1
											);
										} else {
											return false;
										}
									} else {
										return false;
									}
								});
							}

							return _.sortBy(filteredEnums, function (el) {
								return el.label
									.toLowerCase()
									.startsWith(searchValue.toLowerCase())
									? -1
									: 1;
							});
						} else {
							return [];
						}
					};

					// Add Formula to grammar as a column
					$scope.addFormuleToWidget = function () {
						let usedFormule = getUsedFormule($scope.formulaRoot.function_name);
						let type =
							usedFormule.response && usedFormule.response.type
								? usedFormule.response.type
								: $scope.formulaRoot.last_data_type
								? $scope.formulaRoot.last_data_type
								: usedFormule.type
								? usedFormule.type
								: 'string';
						let maxId = $scope.getMaxId();
						$scope.updateFormula = false;
						let updatedFormulasCopy = angular.copy($scope.formulaRoot);
						updatedFormulasCopy.data_type = type;
						updatedFormulasCopy.last_data_type = type;
						updatedFormulasCopy.active = updatedFormulaActive;
						// clear enums
						clearEnumsBeforeSave(updatedFormulasCopy);
						if ($scope.isNewFormule) {
							let col = {
								description_formule: $scope.description,
								banalisee: null,
								distinct: null,
								field: $scope.libelle,
								column_alias: $scope.libelle,
								description: $scope.description,
								id: maxId + 1,
								pivot: null,
								sort: '',
								type: type,
								list:
									usedFormule && usedFormule.response
										? usedFormule.response.list
										: undefined,
								uca_id: null,
								formula: updatedFormulasCopy,
								is_formule: true,
								called_formulas: [],
								uuid: generateUuid('_'),
							};
							col.visible = true;
							col.col = $scope.selectedWidget.grammar.columnsTemp.length;
							if ($scope.impactOn) {
								col.impact = $scope.impact;
							}
							$scope.selectedWidget.grammar.columnsTemp.push(col);
							$scope.selectedWidget.grammar.columns = angular.copy(
								$scope.selectedWidget.grammar.columnsTemp
							);
						} else {
							let o = {
								description_formule: $scope.description,
								banalisee: null,
								distinct: null,
								field: $scope.libelle,
								column_alias: $scope.libelle,
								description: $scope.description,
								id: $scope.selectedWidget.grammar.columnsTemp[
									$scope.indiceToUpdate
								].id,
								pivot: null,
								sort: '',
								type: type,
								list:
									usedFormule && usedFormule.response
										? usedFormule.response.list
										: undefined,
								uca_code: '' + (maxId + 1),
								uca_id: null,
								formula: updatedFormulasCopy,
								is_formule: true,
								called_formulas: [],
								col: $scope.updatedFormula.id,
								uuid: $scope.selectedWidget.grammar.columnsTemp[
									$scope.indiceToUpdate
								].uuid,
							};
							if ($scope.impactOn) {
								o.impact = $scope.impact;
							}
							$scope.selectedWidget.grammar.columnsTemp[$scope.indiceToUpdate] =
								o;

							// FIXME: buildFormulaStr is used to construct a string from formula_name but formula_name is now using
							//  formula identity field so the string is not correct !
							//  updateParentFormula is called bet looking at the implementation it does nothing !
							// updateParentFormula(
							// 	$scope.updatedFormula,
							// 	buildFormulaStr($scope.formulaRoot)
							// );

							$scope.selectedWidget.grammar.columns = angular.copy(
								$scope.selectedWidget.grammar.columnsTemp
							);

							$scope.functionString = '';
							$scope.formulaRoot = {};
						}

						$scope.clearFormula(false);
						vm.countMyFormulasOp();
						vm.refreshFormulaDisplay();
						vm.widgetData.widgetMenuData.dataUpdated = false;
					};

					function stopEvent(event) {
						if (event) {
							event.preventDefault();
							event.stopPropagation();
						}
					}

					function focusOnLabel(event) {
						stopEvent(event);
						angular.element(FORMULA_LABEL)[0].selectionEnd = 0;
						angular.element(FORMULA_LABEL)[0].selectionStart = 0;
						setTimeout(function () {
							$(FORMULA_LABEL).focus();
						}, 100);
						formulaTextAreaFocused = false;
					}

					// clear inputs
					$scope.clearFormula = function (focus) {
						$scope.isNewFormule = true;
						$scope.formuleBuilded = '';
						$scope.formuleDisplayed = '';
						$scope.currentFormule = {};
						$scope.firstFunction = true;
						$scope.focused = false;
						$scope.functionSignature = '';
						$scope.functionDescription = '';
						$scope.libelle = '';
						$scope.description = '';
						$scope.param = null;
						$scope.updatedFormula = null;
						$scope.updateFormula = false;
						$scope.functionString = ''; // used to display selected formula in text area
						$scope.formulaRoot = null;
						$scope.existFormule = true;
						$scope.isDuplicateMode = false;
						delete $scope.clipboard;
						delete $scope.paramAcceptedTypes;
						cleanSuggestions();
						if (focus) {
							setTimeout(function () {
								focusOnLabel();
							}, 50);
						}
					};

					$scope.newFormulaByClear = function (event) {
						stopEvent(event);
						$scope.clearFormula(true);
						$scope.enableCreatingNewFormula = false;
					};

					$scope.getMaxId = function () {
						var id = 1;
						for (
							var i = 0;
							i < $scope.selectedWidget.grammar.columnsTemp.length;
							i++
						) {
							if (
								$scope.selectedWidget.grammar.columnsTemp[i].id != undefined &&
								id < $scope.selectedWidget.grammar.columnsTemp[i].id
							) {
								id = $scope.selectedWidget.grammar.columnsTemp[i].id;
							}
						}
						return id;
					};

					// FIXME: TO REMOVE - called_formulas is always an empty array, this function has therefore no impact !
					function updateParentFormula(updatedFormula, newExpression) {
						for (
							var i = 0;
							i < $scope.selectedWidget.grammar.columnsTemp.length;
							i++
						) {
							if (
								$scope.selectedWidget.grammar.columnsTemp[i].called_formulas !=
								undefined
							) {
								if (
									$scope.selectedWidget.grammar.columnsTemp[
										i
									].called_formulas.indexOf(updatedFormula.id) > -1
								) {
									var formulaColumn =
										$scope.selectedWidget.grammar.columnsTemp[i];
									formulaColumn.expression_formule =
										formulaColumn.expression_formule.replace(
											updatedFormula.oldExpression,
											newExpression
										);
									$scope.selectedWidget.grammar.columnsTemp[i] = formulaColumn;
								}
							}
						}
					}

					$scope.deleteFormule = function (modalId) {
						if ($scope.formuleIsUsed) {
							return;
						}
						//Delete impacted columns
						var deletedColumns = [];
						$scope.columnsBeforeDelete = angular.copy(
							$scope.selectedWidget.grammar.columnsTemp
						);
						$scope.columns_BeforeDelete = angular.copy(
							$scope.selectedWidget.grammar.columns_tmp
						);
						for (var x = 0; x < $scope.formuleToDelete.length; x++) {
							var id = $scope.formuleToDelete[x];
							for (
								var i = 0;
								i < $scope.selectedWidget.grammar.columnsTemp.length;
								i++
							) {
								if (id == $scope.selectedWidget.grammar.columnsTemp[i].id) {
									if (
										$scope.selectedWidget.grammar.columnsTemp[i].formula &&
										$scope.selectedWidget.grammar.columnsTemp[i].formula
											.function_name === 'dc.ranking' &&
										$scope.selectedWidget.grammar.columnsTemp[i].formula
											.semantic_order !== undefined
									) {
										for (
											var t = 0;
											t < $scope.selectedWidget.grammar.columnsTemp.length;
											t++
										) {
											if (
												$scope.selectedWidget.grammar.columnsTemp[t]
													.parent_id == undefined &&
												$scope.selectedWidget.grammar.columnsTemp[t].id != id &&
												$scope.selectedWidget.grammar.columnsTemp[t].field &&
												$scope.selectedWidget.grammar.columnsTemp[
													t
												].field.startsWith(
													$scope.selectedWidget.grammar.columnsTemp[i].field
												)
											) {
												$scope.selectedWidget.grammar.columnsTemp[t].parent_id =
													id;
											}
										}
									}
									$scope.selectedWidget.grammar.columnsTemp.splice(i, 1);
									if ($scope.selectedWidget.grammar.columns_tmp) {
										$scope.selectedWidget.grammar.columns_tmp.splice(i, 1);
									}
									deletedColumns.push(id);
								}
							}
							// Delete ranking additional columns
							for (
								var j = 0;
								j < $scope.selectedWidget.grammar.columnsTemp.length;
								j++
							) {
								if (
									_.indexOf(
										deletedColumns,
										$scope.selectedWidget.grammar.columnsTemp[j].parent_id
									) != -1
								) {
									$scope.selectedWidget.grammar.columnsTemp.splice(j, 1);
									if ($scope.selectedWidget.grammar.columns_tmp) {
										$scope.selectedWidget.grammar.columns_tmp.splice(j, 1);
									}
									j--;
								}
							}
						}
						updateWidgetColumnDeleteImpact(
							$scope.selectedWidget,
							$scope.columnToDelete_id,
							$scope.columnToDelete_index
						);

						$scope.updateColIndex();
						vm.countMyFormulasOp();
						vm.refreshFormulaDisplay();
						$('#' + modalId).modal('hide');
						$scope.save(true);

						return id;
					};

					$scope.showDeleteConfirmationModal = function (id, col, modalId) {
						$scope.formuleToDelete = [];
						$scope.formuleToDelete.push(id);
						$scope.formulaToDeleteLabel = col.column_alias;
						$scope.columnToDelete_id = id;
						$scope.columnToDelete_index =
							$scope.selectedWidget.grammar.columnsTemp.indexOf(col);
						$scope.formuleIsUsed = false;
						for (
							var i = 0;
							i < $scope.selectedWidget.grammar.columnsTemp.length;
							i++
						) {
							var column = $scope.selectedWidget.grammar.columnsTemp[i];
							if (column.id != id && column.formula) {
								$scope.formuleIsUsed =
									vm.modes && vm.modes.refByLabel
										? checkIfFormulaUsedInColumnsByLabel(
												column.formula,
												$scope.formulaToDeleteLabel
										  )
										: checkIfFormulaUsedInColumnsByIndex(
												column.formula,
												$scope.columnToDelete_index
										  );
								if ($scope.formuleIsUsed) {
									break;
								}
							}
						}
						if (!$scope.formuleIsUsed) {
							for (var g in $scope.selectedWidget.groupes) {
								if (
									$scope.selectedWidget.groupes[g].pos ==
									$scope.columnToDelete_index
								) {
									$scope.formuleIsUsed = true;
									break;
								}
							}
						}

						if (!$scope.formuleIsUsed) {
							for (var v in $scope.selectedWidget.functions) {
								if (
									$scope.selectedWidget.functions[v].pos ==
									$scope.columnToDelete_index
								) {
									$scope.formuleIsUsed = true;
									break;
								}
							}
						}

						if (!$scope.formuleIsUsed) {
							var rules = [];
							getFlattenRules(rules, $scope.selectedWidget.grammar.rules);
							for (var r in rules) {
								if (rules[r].column_ref == col.id) {
									$scope.formuleIsUsed = true;
									break;
								}
							}
						}

						$('#' + modalId + '-' + $scope.$id).modal(
							{ backdrop: false, keyboard: false },
							'show'
						);
					};

					$scope.showDeleteAllFormulas = function (modalId) {
						$('#' + modalId + '-' + $scope.$id).modal(
							{ backdrop: false, keyboard: false },
							'show'
						);
					};

					$scope.deleteAllFormulas = function (modalId) {
						let tempCols = angular.copy(
							$scope.selectedWidget.grammar.columnsTemp
						);
						for (
							var i = $scope.selectedWidget.grammar.columnsTemp.length - 1;
							i >= 0;
							i--
						) {
							if ($scope.selectedWidget.grammar.columnsTemp[i].formula) {
								let colId = $scope.selectedWidget.grammar.columnsTemp[i].id;
								let colIndex = tempCols.indexOf(
									$scope.selectedWidget.grammar.columnsTemp[i]
								);
								$scope.selectedWidget.grammar.columnsTemp.splice(i, 1);
								if ($scope.selectedWidget.grammar.columns_tmp) {
									$scope.selectedWidget.grammar.columns_tmp.splice(i, 1);
								}
								updateWidgetColumnDeleteImpact(
									$scope.selectedWidget,
									colId,
									colIndex
								);
							}
						}
						$scope.updateColIndex();
						vm.countMyFormulasOp();
						vm.refreshFormulaDisplay();
						$('#' + modalId).modal('hide');
						$scope.save(true);
						$scope.cancel();
					};

					$scope.hideModal = function (modalId) {
						$('#' + modalId).modal('hide');
						if (modalId.includes('formulaSuggestions')) {
							$scope.paramAcceptedTypes = [];
						}
					};

					// update column indexes after delete
					$scope.updateColIndex = function () {
						let idsMaps = {};
						for (
							var i = 0;
							i < $scope.selectedWidget.grammar.columnsTemp.length;
							i++
						) {
							var column = $scope.selectedWidget.grammar.columnsTemp[i];
							// get old id so we update existing sub formulas
							let oldId = _.findIndex(
								$scope.selectedWidget.grammar.columns,
								function (e) {
									return (
										e.id === $scope.selectedWidget.grammar.columnsTemp[i].id
									);
								}
							);
							if (oldId > -1) {
								// get old id so we update existing sub formulas
								idsMaps['COLL' + oldId] = 'COLL' + i;
							}
							if (column.col != undefined) {
								column.col = i;
								$scope.selectedWidget.grammar.columnsTemp[i] = column;
							}
						}
						if (Object.keys(idsMaps).length !== 0) {
							// update old column indexes
							for (let j in $scope.selectedWidget.grammar.columnsTemp) {
								if ($scope.selectedWidget.grammar.columnsTemp[j].formula) {
									updateSubFormulaIndexes(
										$scope.selectedWidget.grammar.columnsTemp[j].formula,
										idsMaps
									);
								}
							}
						}
					};

					$scope.updateFormule = function (column, indice) {
						$scope.indiceToUpdate = indice;
						// Reset list and exclude current formula
						resetSuggestedFunctionsList();
						$scope.updatedFormula = {};
						$scope.updatedFormula.id = column.col;
						$scope.updatedFormula.oldExpression = column.expression_formule;
						$scope.libelle = column.column_alias;
						$scope.description = column.description;
						if ($scope.impactOn) {
							$scope.impact = column.impact != undefined ? column.impact : 0;
						}
						$scope.isNewFormule = false;
						$scope.firstFunction = false;
						$scope.updateFormula = true;
						$scope.formulaRoot = column.formula;
						$scope.functionString = buildFormulaDisplayed(
							$scope.formulaRoot,
							$scope.caracMode,
							ruleTra,
							$scope.selectedWidget.grammar.columns,
							$scope.elements
						);
						$scope.existFormule = false;
						$scope.enableCreatingNewFormula = false;
						updatedFormulaActive =
							column && column.formula ? column.formula.active : true;
						focusOnFormulaAreaPos0();
					};

					$scope.cancelEditing = function () {
						$scope.clearFormula(false);
						$scope.updateFormula = false;
						$scope.enableCreatingNewFormula = true;
					};

					$scope.duplicateFormula = function (column) {
						resetSuggestedFunctionsList();
						$scope.newFormulaByClear();
						$scope.libelle = angular.copy(column.column_alias) + ' (copie)';
						$scope.description = angular.copy(column.description);
						$scope.formulaRoot = angular.copy(column.formula);
						delete $scope.formulaRoot.uuid;
						$scope.functionString = buildFormulaDisplayed(
							$scope.formulaRoot,
							$scope.caracMode,
							ruleTra,
							$scope.selectedWidget.grammar.columns,
							$scope.elements
						);
						$scope.isDuplicateMode = true;
					};

					$scope.formuleBuilded = '';
					$scope.formuleDisplayed = '';

					$scope.isCarac = function (noeud) {
						for (
							var i = 0;
							i < $scope.selectedWidget.grammar.columns.length;
							i++
						) {
							if (
								noeud.isCarac &&
								noeud.valeur ==
									$scope.selectedWidget.grammar.columns[i].column_alias
							) {
								return i;
							}
						}
						return -1;
					};

					$scope.isArray = function (type) {
						return angular.isArray(type);
					};

					$scope.$watch(
						'selectedWidget.grammar.columnsTemp',
						function (newValue) {
							if ($scope.onlyOneFormula && newValue) {
								$scope.containsAtLeastOneF = false;
								angular.forEach(newValue, function (column) {
									if (column.is_formule) {
										$scope.containsAtLeastOneF = true;
									}
								});
							}
						},
						true
					);

					$scope.getLabelForGivenCol = function (col) {
						if ($scope.caracMode) {
							return getCorrespondingCaracLabel(
								col,
								$scope.selectedWidget.grammar.columns
							);
						} else {
							return getCorrespondingColLabel(
								col,
								$scope.selectedWidget.grammar.columns
							);
						}
					};

					$scope.notRootFunction = function () {
						return $scope.formulaRoot != null;
					};

					$scope.insertFunction = function (index, isFormula, isFromCat) {
						let selectionStart =
							angular.element(FORMULA_TEXT_AREA)[0].selectionStart;
						let formula;
						if (!isFromCat) {
							formula = generateFormulaForInsert(isFormula, index);
						} else {
							formula = $scope.selectedFormulaCat.formula;
						}
						// function param can itself be a function
						let currentFunctionName = $scope.concernedParam
							? $scope.concernedParam.function_name
							: undefined;
						if (currentFunctionName === formula.function_name && !isFromCat) {
							return;
						}

						let selectedParamObj;
						if (!$scope.formulaRoot) {
							$scope.formulaRoot = formula;
							$scope.formulaRoot.isRoot = true;
							$scope.formulaRoot.active = true;
						} else {
							selectedParamObj = angular.copy($scope.concernedParam);
							if (!selectedParamObj) {
								selectedParamObj =
									$scope.formulaRoot != null
										? getParamObject($scope.formulaRoot, selectionStart)
										: null;
							}
							if (
								($scope.selectionStartPos == 0 &&
									selectedParamObj == undefined) ||
								(selectedParamObj && selectedParamObj.start_position == 0)
							) {
								$scope.formulaRoot = formula;
								$scope.formulaRoot.isRoot = true;
							} else if (selectedParamObj != undefined) {
								replaceParam($scope.formulaRoot, selectedParamObj.id, formula);
							} else if (
								$scope.selectionStartPos <= $scope.formulaRoot.start_position
							) {
								var firstParam = $scope.formulaRoot;
								$scope.formulaRoot = formula;
								$scope.formulaRoot.params[0] = firstParam;
							} else if (
								$scope.selectionStartPos >=
								$scope.formulaRoot.start_position + 1
							) {
								$scope.formulaRoot.params[0] = formula;
							} else {
								$scope.formulaRoot = formula;
							}
						}
						// handle list create object
						buildFormulaObject();
						let parentFunctionData = getFunctionForParam(
							selectedParamObj,
							$scope.formulaRoot
						);
						let parentFunctionDataRoot;
						if (parentFunctionData) {
							parentFunctionDataRoot = getFunctionForParam(
								parentFunctionData.object,
								$scope.formulaRoot
							);
						}

						if (parentFunctionDataRoot) {
							parentFunction = getFunctionForParam(
								parentFunctionDataRoot.object.params[
									parentFunctionDataRoot.index
								],
								$scope.formulaRoot
							);
							parentFunction.object =
								parentFunction && parentFunction.object
									? parentFunction.object.params[parentFunctionData.index]
									: parentFunction.object;
							parentFunction.index =
								parentFunction && parentFunction.index
									? parentFunction.object.params[parentFunctionData.index]
									: parentFunction.index;
						} else if (parentFunctionData) {
							parentFunction = getFunctionForParam(
								$scope.formulaRoot.params[parentFunctionData.index],
								$scope.formulaRoot
							);
						}

						// newly inserted function from the view, using function_name should have no impact ?!
						if (
							parentFunction &&
							parentFunction.object &&
							isListCreate(parentFunction.object.function_name)
						) {
							var paramsList = parentFunction.object.params;
							var fstParamType =
								paramsList[0] !== undefined
									? paramsList[0].data_type !== undefined
										? paramsList[0].data_type
										: 'string'
									: undefined;
							var gParentFunction = getFunctionForParam(
								parentFunction.object,
								$scope.formulaRoot
							);
							var usedFormula;
							if (gParentFunction) {
								usedFormula = getUsedFormule(
									gParentFunction.object.function_name
								);
								var fReplacement;
								if (
									usedFormula &&
									usedFormula.response &&
									usedFormula.response.list
								) {
									fReplacement = gParentFunction.object;
									fReplacement.data_type = fstParamType;
									fReplacement.last_data_type = fReplacement.data_type;
									replaceParam(
										$scope.formulaRoot,
										gParentFunction.object.id,
										fReplacement
									);
								}
							} else {
								usedFormula = getUsedFormule(
									parentFunction.object.function_name
								);
								if (
									usedFormula &&
									usedFormula.response &&
									usedFormula.response.list
								) {
									fReplacement = parentFunction.object;
									fReplacement.data_type = fstParamType;
									fReplacement.last_data_type = fReplacement.data_type;
									replaceParam(
										$scope.formulaRoot,
										parentFunction.object.id,
										fReplacement
									);
								}
							}
							for (var i = 1; i < paramsList.length; i++) {
								if (fstParamType !== paramsList[i].data_type) {
									var rFormulaList = {
										function_name: null,
										params: [],
										type: 'value',
										value: '',
									};
									replaceParam(
										$scope.formulaRoot,
										paramsList[i].id,
										rFormulaList
									);
								}
							}
							buildFormulaObject();
						}
						buildFormulaObject();
						$scope.paramAcceptedTypes = [];
						// go to the start of param
						if (formula.params && formula.params.length > 0) {
							let paramPosition = formula.params[0].start_position;
							selectionStart =
								paramPosition != 0 ? paramPosition + 1 : start_position;
						}
						goToPositionAndAfterFocus(selectionStart, true, isFromCat);
					};

					// applies when a column is selected from the dropdown while populating current formula parameters
					$scope.insertValOrColumns = function (element, isColumn, rules) {
						var selectionStart =
							angular.element(FORMULA_TEXT_AREA)[0].selectionStart;
						if (
							selectionStart == 0 ||
							$scope.concernedParam.start_position == 0 ||
							$scope.concernedParam.isRoot ||
							$scope.formulaRoot === null ||
							$scope.formulaRoot.function_name === undefined
						) {
							toaster.error(
								gettextCatalog.getString(
									'Le premier élément doit être une fonction'
								)
							);
							throw new UserException(
								gettextCatalog.getString(
									'Le premier élément doit être une fonction'
								)
							);
						}
						var selectedParamObj =
							$scope.formulaRoot != null
								? getParamObject($scope.formulaRoot, selectionStart)
								: null;
						var selectedFunction = element;

						var formula = {
							function_name: null,
							params: [],
						};

						if (rules) {
							formula.rule = rules;
							formula.is_rule = true;
							formula.type = 'rule';
							formula.value = '';
							formula.data_type = 'boolean';
						} else {
							formula.value =
								!isColumn && $scope.isEnum
									? getCorrespondingEnumValue(
											selectedFunction.label,
											$scope.enums
									  )
									: isColumn
									? getCorrespondingCol(selectedFunction.label)
									: selectedFunction.label;
							formula.type =
								!isColumn && $scope.isEnum
									? 'enum'
									: isColumn
									? 'column'
									: 'value';
							formula.is_semantic_context_ranking =
								element.is_semantic_context_ranking;
							if (element.type) {
								formula.data_type = element.type;
							} else {
								formula.data_type = typedValueType;
								if (!formula.data_type) {
									formula.data_type =
										$scope.paramAcceptedTypes &&
										$scope.paramAcceptedTypes[
											$scope.paramAcceptedTypes.length - 1
										]
											? $scope.paramAcceptedTypes[
													$scope.paramAcceptedTypes.length - 1
											  ]
											: element.group === $scope.valuesGroup
											? 'string'
											: undefined;
								}
							}
						}

						if (!isColumn && $scope.isEnum) {
							formula.enums = $scope.enums;
						}
						if (isColumn) {
							$scope.formulaRoot.last_data_type = element.type;
						} else {
							$scope.formulaRoot.last_data_type = 'string';
						}
						if (isColumn && $scope.caracMode) {
							formula.type = 'carac';
							formula.value = selectedFunction.value;
						}
						var generatedString = formula.value;

						formula.end_position = generatedString.length;

						if ($scope.formulaRoot != null) {
							if (
								$scope.selectionStartPos == 0 &&
								selectedParamObj == undefined
							) {
								$scope.formulaRoot = formula;
							} else if (selectedParamObj != undefined) {
								replaceParam($scope.formulaRoot, selectedParamObj.id, formula);
							} else if (
								$scope.selectionStartPos <= $scope.formulaRoot.start_position
							) {
								var firstParam = $scope.formulaRoot;
								$scope.formulaRoot = formula;
								$scope.formulaRoot.params[0] = firstParam;
							} else if (
								$scope.selectionStartPos >=
								$scope.formulaRoot.start_position + 1
							) {
								$scope.formulaRoot.params[0] = formula;
							} else {
								$scope.formulaRoot = formula;
							}
						} else {
							$scope.formulaRoot = formula;
						}

						buildFormulaObject();

						var parentFunction = getFunctionForParam(
							selectedParamObj,
							$scope.formulaRoot
						);
						if (
							parentFunction &&
							parentFunction.object &&
							parentFunction.object.function_name == 'dc.ranking'
						) {
							var params = parentFunction.object.params;
							if (
								params[1].is_semantic_context_ranking ||
								element.is_semantic_context_ranking
							) {
								if (params[0].type == 'column') {
									let firstParamValue = params[0] ? params[0].value : '';
									let isLabelMode = vm.modes && vm.modes.refByLabel;
									let valueToSearch = isLabelMode
										? firstParamValue
										: parseInt(firstParamValue.replace('COLL', ''));

									if (valueToSearch) {
										let columns = _.filter($scope.elements, function (elm) {
											return elm.group == 'Données';
										});
										let el = _.filter(columns, function (elm) {
											return (
												(!isLabelMode && elm.id == valueToSearch) ||
												(isLabelMode && elm.label == valueToSearch)
											);
										});
										if (el && el[0].type != 'words') {
											let rFormula = {
												function_name: null,
												params: [],
												type: 'value',
												value: '',
											};
											replaceParam($scope.formulaRoot, params[0].id, rFormula);
											buildFormulaObject();
										}
									}
								}
							}
						} else if (
							parentFunction &&
							parentFunction.object &&
							(isListCreate(parentFunction.object.function_name) ||
								(selectedFunction && selectedFunction.list))
						) {
							var paramsList = parentFunction.object.params;
							var fstParamType =
								paramsList[0] !== undefined
									? paramsList[0].data_type !== undefined
										? paramsList[0].data_type
										: 'string'
									: undefined;
							var gParentFunction = getFunctionForParam(
								parentFunction.object,
								$scope.formulaRoot
							);
							var usedFormula;
							if (gParentFunction) {
								usedFormula = getUsedFormule(
									gParentFunction.object.function_name
								);
								var fReplacement;
								if (
									usedFormula &&
									usedFormula.response &&
									usedFormula.response.list
								) {
									fReplacement = gParentFunction.object;
									fReplacement.data_type = fstParamType;
									fReplacement.last_data_type = fReplacement.data_type;
									replaceParam(
										$scope.formulaRoot,
										gParentFunction.object.id,
										fReplacement
									);
								}
							} else {
								usedFormula = getUsedFormule(
									parentFunction.object.function_name
								);
								if (
									usedFormula &&
									usedFormula.response &&
									usedFormula.response.list
								) {
									fReplacement = parentFunction.object;
									fReplacement.data_type = fstParamType;
									fReplacement.last_data_type = fReplacement.data_type;
									replaceParam(
										$scope.formulaRoot,
										parentFunction.object.id,
										fReplacement
									);
								}
							}
							for (var i = 1; i < paramsList.length; i++) {
								if (fstParamType !== paramsList[i].data_type) {
									var rFormulaList = {
										function_name: null,
										params: [],
										type: 'value',
										value: '',
									};
									replaceParam(
										$scope.formulaRoot,
										paramsList[i].id,
										rFormulaList
									);
								}
							}
							buildFormulaObject();
						}
						$scope.paramAcceptedTypes = [];
						// go to the start of param
						goToPositionAndAfterFocus(selectionStart, true);
					};

					$scope.openRules = function (event) {
						stopEvent(event);
						$scope.suggSearchOn = false;
						vm.data.filterRules = $scope.concernedParam.rule
							? $scope.concernedParam.rule
							: {
									rules: [],
									condition: 'AND',
							  };
						vm.data.saveFilterMethod = saveFilterForRuleFormula;
						vm.data.additionalColumns = [];
						if (vm.data.step) {
							for (var f in vm.data.step.formulas) {
								vm.data.additionalColumns.push({
									type: vm.data.step.formulas[f].data_type,
									is_list: vm.data.step.formulas[f].is_list,
									lib: vm.data.step.formulas[f].lib,
									uuid: vm.data.step.formulas[f].uuid,
									alias: 'COL_' + vm.data.step.formulas[f].uuid,
								});
							}
						}
						vm.data.filterTitle = gettextCatalog.getString('Règles');
						$scope.libelle
							? (vm.data.filterTitle += ' ' + $scope.libelle)
							: (vm.data.filterTitle = vm.data.filterTitle);
						vm.data.disableLimit = true;
						vm.data.disableActivation = true;
						vm.data.showFilter = true;
					};

					// when a user clicks on a function label or function parameter
					$scope.openSuggestionsModal = function (event) {
						// Init formula details
						$scope.showFunctionDetails = true;
						$scope.focusIndex = 0;
						$scope.functionDetails = {
							name: '',
							params: [],
						};
						$scope.selectionStartPos =
							angular.element(FORMULA_TEXT_AREA)[0].selectionStart;
						$scope.concernedParam =
							$scope.formulaRoot != null
								? getParamObject($scope.formulaRoot, $scope.selectionStartPos)
								: null;
						$scope.isRootFunction =
							$scope.formulaRoot == null ||
							$scope.concernedParam.start_position == 0
								? true
								: $scope.concernedParam.isRoot;
						$scope.isEnum =
							$scope.concernedParam != null
								? $scope.concernedParam.type == 'enum'
								: false;

						$scope.enums =
							$scope.concernedParam != null ? $scope.concernedParam.enums : [];
						$scope.search.value = getSearchValueFromConcernedParam($scope.concernedParam)
						$scope.isFunction = false;
						$scope.isList = false;
						$scope.typedValueIsValid = false;
						parentFunction = getFunctionForParam(
							$scope.concernedParam,
							$scope.formulaRoot
						);

						// TODO: check if value function_name can impact comparisons checks
						$scope.isDateToStrEnums =
							$scope.isEnum &&
							parentFunction &&
							parentFunction.object &&
							(parentFunction.object.function_name == 'date.to_str' ||
								parentFunction.object.function_name == 'date.to_strz' ||
								parentFunction.object.function_name == 'str.to_date' ||
								parentFunction.object.function_name == 'str.to_datez');
						var usedFormula;
						$scope.getElemmentListTypeOnly = false;
						if (
							parentFunction &&
							parentFunction.object &&
							parentFunction.object
						) {
							usedFormula = getUsedFormule(parentFunction.object.function_name);

							$scope.getElemmentListTypeOnly =
								usedFormula &&
								usedFormula.input &&
								usedFormula &&
								usedFormula.input[parentFunction.index] &&
								usedFormula.input[parentFunction.index].is_typed_list;
						}

						if (
							parentFunction &&
							parentFunction.object &&
							parentFunction.object &&
							parentFunction.object.params &&
							parentFunction.object.params[1] &&
							parentFunction.object.params[1].id !== $scope.concernedParam.id &&
							parentFunction.object.function_name === 'dc.ranking' &&
							parentFunction.object.params[1].is_semantic_context_ranking
						) {
							$scope.wordsOnly = true;
						} else {
							$scope.wordsOnly = false;
						}
						if (
							parentFunction &&
							parentFunction.object &&
							parentFunction.object &&
							parentFunction.object.params &&
							parentFunction.object.function_name === 'dc.ranking'
						) {
							$scope.rankingFunctionParams = true;
							$scope.filteredType = undefined;
							if (
								parentFunction.object.params[1] &&
								parentFunction.object.params[1].id == $scope.concernedParam.id
							) {
								if (parentFunction.object.params[0].value) {
									$scope.filteredType = parentFunction.object.params[0]
										.data_type
										? parentFunction.object.params[0].data_type
										: parentFunction.object.params[0].value
										? getColumnType(parentFunction.object.params[0].value)
										: undefined;
								} else {
									$scope.filteredType =
										parentFunction.object.params[0].supportedTypes;
								}
							}
							if (
								parentFunction.object.params[0] &&
								parentFunction.object.params[0].id == $scope.concernedParam.id
							) {
								if (parentFunction.object.params[1].value) {
									$scope.filteredType = parentFunction.object.params[1]
										.data_type
										? parentFunction.object.params[1].data_type
										: parentFunction.object.params[1].value
										? getColumnType(parentFunction.object.params[1].value)
										: undefined;
								} else {
									$scope.filteredType =
										parentFunction.object.params[0].supportedTypes;
								}
							}
						} else {
							$scope.rankingFunctionParams = false;
						}
						if (
							parentFunction &&
							parentFunction.object &&
							parentFunction.object &&
							isListCreate(parentFunction.object.function_name)
						) {
							$scope.listFunction = true;
							if (
								parentFunction.object.params &&
								parentFunction.object.params[0]
							) {
								$scope.filteredType = parentFunction.object.params[0].data_type;
							}
						} else {
							$scope.listFunction = false;
						}
						if ($scope.search.value == null) {
							if ($scope.concernedParam != null) {
								if (
									$scope.concernedParam.type == 'column' ||
									$scope.concernedParam.type == 'carac'
								) {
									$scope.search.value = $scope.caracMode
										? getCorrespondingCaracLabel(
												$scope.concernedParam.value,
												$scope.selectedWidget.grammar.columns
										  )
										: getCorrespondingColLabel(
												$scope.concernedParam.value,
												$scope.selectedWidget.grammar.columns
										  );
								} else if ($scope.isEnum) {
									var index = undefined;
									for (var i in parentFunction.object.params) {
										if (
											parentFunction.object.params[i].id ==
											$scope.concernedParam.id
										) {
											index = i;
											break;
										}
									}
									$scope.search.value = getCorrespondingEnumLabel(
										$scope.concernedParam.value,
										$scope.elements,
										false,
										parentFunction.object.function_name,
										index,
										$scope.concernedParam
									);
									$scope.enums =
										$scope.concernedParam != null
											? $scope.concernedParam.enums
											: [];
								} else if ($scope.concernedParam.type == 'function') {
									$scope.search.value = getFormulaDisplayName($scope.concernedParam, false);
								} else {
									$scope.search.value = $scope.concernedParam.value;
								}
							}
						}
						if (
							$scope.concernedParam != undefined &&
							$scope.concernedParam != null
						) {
							if ($scope.concernedParam.type == 'function') {
								$scope.isFunction = true;
								$scope.isList =
									isListCreate($scope.concernedParam.function_name) ||
									$scope.concernedParam.function_name.toUpperCase() == 'ARGS';
								$scope.argsNumber = $scope.concernedParam.params.length;
							}
						}
						$scope.search.value =
							$scope.search.value != undefined ? $scope.search.value : '';
						$scope.oldSearchValue = angular.copy($scope.search.value);
						if (
							parentFunction &&
							parentFunction.object &&
							parentFunction.object.function_name == 'ARGS'
						) {
							var parentFunctionArgs = getFunctionForParam(
								parentFunction.object,
								$scope.formulaRoot
							);
							var usedFormulaParentArgs = getUsedFormule(
								parentFunctionArgs.object.function_name
							);
							$scope.paramAcceptedTypes =
								usedFormulaParentArgs != undefined &&
								usedFormulaParentArgs.input != undefined &&
								parentFunctionArgs != undefined &&
								parentFunctionArgs.index != undefined &&
								usedFormulaParentArgs.input[parentFunctionArgs.index] !=
									undefined &&
								usedFormulaParentArgs.input[parentFunctionArgs.index].type !=
									undefined
									? usedFormulaParentArgs.input[parentFunctionArgs.index].type
									: [];
						} else {
							$scope.paramAcceptedTypes =
								usedFormula != undefined &&
								usedFormula.input != undefined &&
								parentFunction != undefined &&
								parentFunction.index != undefined &&
								usedFormula.input[parentFunction.index] != undefined &&
								usedFormula.input[parentFunction.index].type != undefined
									? usedFormula.input[parentFunction.index].type
									: [];
						}

						if ($scope.isFunction) {
							if ($scope.concernedParam != undefined) {
								const found = _.find($scope.elements, function (el) {
									return (
										el.group == $scope.functionsGroup &&
										el.ident == $scope.concernedParam.function_name
									);
								});
								getShowFunctionDetailsData(
									found,
									null,
									$scope.concernedParam.params.length,
									$scope.paramAcceptedTypes
								);
							}
						} else {
							var paramFunction = getFunctionForParam(
								$scope.concernedParam,
								$scope.formulaRoot
							);
							if (paramFunction != undefined) {
								const found = _.find($scope.elements, function (el) {
									return (
										el.group == $scope.functionsGroup &&
										el.ident == paramFunction.object.function_name
									);
								});
								getShowFunctionDetailsData(
									found,
									paramFunction.index,
									paramFunction.object.params.length,
									$scope.paramAcceptedTypes
								);
							}
						}
						$scope.getElementsBySearchValue($scope.search.value);
						$scope.formulaTestFocused = true;
						$scope.showSuggestions = true;
						formulaTextAreaFocused = true;
						if (
							event &&
							event.originalEvent &&
							event.originalEvent.type == 'click' &&
							$scope.concernedParam &&
							$scope.concernedParam.start_position
						) {
							goToPositionAndAfterFocus(
								$scope.concernedParam.start_position,
								false,
								false
							);
						}
					};

					$scope.getElementsBySearchValue = function (searchValue) {
						$scope.elementsV = [];
						$scope.elementsF = [];
						$scope.elementsC = [];
						$scope.elementLists = [];
						$scope.elmDetails = false;

						if ($scope.isEnum) {
							$scope.elementLists = $scope.getEnums($scope.search.value);
							if ($scope.isDateToStrEnums) {
								$scope.elementsV = $scope.getElements(
									searchValue,
									$scope.valuesGroup
								);
							}
						} else {
							if (!$scope.isRootFunction) {
								$scope.elementsV = $scope.getElements(
									searchValue,
									$scope.valuesGroup
								);
								$scope.elementLists = $scope.getElements(
									searchValue,
									$scope.dataGroup
								);
							}
							$scope.elementsF = $scope.getElements(
								searchValue,
								$scope.functionsGroup
							);
							$scope.elementsC = $scope.getElements(
								searchValue,
								$scope.contsGroup
							);
							$scope.elementLists = $scope.typedValueIsValid
								? _.union(
										$scope.elementsV,
										$scope.elementLists,
										$scope.elementsF,
										$scope.elementsC
								  )
								: _.union(
										$scope.elementLists,
										$scope.elementsF,
										$scope.elementsC
								  );
						}

						if (
							$scope.isTypeBoolOnly() &&
							$scope.notRootFunction() &&
							!$scope.concernedParam.isRoot &&
							!$scope.isEnum
						) {
							$scope.elementLists.unshift({ rule: true });
						}

						$scope.focusIndex = 0;
						for (let i in $scope.elementLists) {
							$scope.elementLists[i].navIndex = i;
						}
						if ($scope.typedValueIsValid) {
							for (let i in $scope.elementsV) {
								$scope.elementsV[i].navIndex = i;
							}
						}
					};

					// increase or decrease list arguments number
					$scope.updateArgsNumber = function (operator) {
						if (
							$scope.argsNumber <= MAX_ARGS &&
							$scope.argsNumber >= MIN_ARGS
						) {
							if (operator == 1) {
								if ($scope.argsNumber + 1 <= MAX_ARGS) {
									$scope.argsNumber = $scope.argsNumber + 1;
									updateListArgs();
								}
							} else {
								if ($scope.argsNumber - 1 >= MIN_ARGS) {
									$scope.argsNumber = $scope.argsNumber - 1;
									updateListArgs();
								}
							}
						}
					};

					$scope.getParamLib = function (param, index) {
						var value = param.value;
						if (param.type === 'column') {
							if ($scope.caracMode) {
								value = getCorrespondingCaracLabel(
									value,
									$scope.selectedWidget.grammar.columns,
									$scope.selectedWidget.grammar.columns
								);
							} else {
								value = getCorrespondingColLabel(
									value,
									$scope.selectedWidget.grammar.columns
								);
							}
						} else if (param.type === 'enum') {
							value = getCorrespondingEnumLabel(value, $scope.elements, false);
						}

						return param.function_name != undefined && param.function_name != ''
							? param.function_name
							: value != undefined && value != ''
							? value
							: 'arg' + index;
					};

					$scope.closeConfirmation = function () {
						if (
							(($scope.formulaRoot != {} && $scope.formulaRoot != null) ||
								vm.widgetData.widgetMenuData.dataUpdated) &&
							angular.isFunction($scope.showWidgetMenu) &&
							!$scope.menuHidden
						) {
							$scope.flags.confirmationIsOpend = true;
						} else {
							$scope.flags.confirmationIsOpend = false;
							if (vm.widgetData.onCancelFormuleAction) {
								vm.widgetData.onCancelFormuleAction();
							}
							vm.closeModal();
						}
					};

					let showParamsDetailsTime;
					$scope.showParamsDetailsAction = function (elm, event, type) {
						if ($scope.isEnum) {
							return;
						}
						showParamsDetailsTime = $timeout(function () {
							$scope.showParamsDetails(elm, event, type);
						}, 1500);
					};

					$scope.cancelParamsDetailsAction = function () {
						if ($scope.isEnum) {
							return;
						}
						$timeout.cancel(showParamsDetailsTime);
						$scope.elmDetails = false;
					};

					$scope.showParamsDetails = function (elm, event, type) {
						$scope.elmDetails = !$scope.elmDetails && $scope.showSuggestions;
						$scope.elmDetails =
							elm.id != $scope.selectedId && $scope.showSuggestions
								? true
								: $scope.elmDetails;
						$scope.selectedId = elm.id;
						$scope.paramsDesciption = [];
						$scope.elemName = '';
						if ($scope.elmDetails == true) {
							$scope.xInfoPosition = event.clientX;
							$scope.yInfoPosition = event.clientY;
							if (type == $scope.functionsGroup) {
								$scope.elemName = elm.label;
								$scope.elemName =
									elm.description != ''
										? elm.label + ' : ' + elm.description
										: $scope.elemName;
								for (var t = 0; t < elm.input.length; t++) {
									$scope.paramsDesciption[t] = {
										label: elm.input[t].label,
										description: elm.input[t].description,
										types: [],
									};

									var types = [];
									elm.input[t].type.forEach(function (element) {
										types.push($scope.getTypeLabel(element, elm.input[t].list));
									});

									$scope.paramsDesciption[t].types = $sce.trustAsHtml(
										' (' + types.toString() + ')'
									);
								}
							} else if (type == $scope.contsGroup) {
								$scope.elemName = elm.label;
								$scope.elemName =
									elm.description != ''
										? elm.label + ' : ' + elm.description
										: $scope.elemName;
							} else if (type == $scope.dataGroup) {
								$scope.elemName = elm.column_alias;
								$scope.elemName =
									elm.description != '' && elm.description != undefined
										? elm.label + ' : ' + elm.description
										: $scope.elemName;
							}
						}
					};

					// Filter function list by type
					$scope.filterFunctionByReturnType = function (f) {
						let type =
							f && f.response && f.response.type ? f.response.type : f.type;
						let list =
							f && f.response && f.response.list ? f.response.list : f.list;
						if (type && vm.returnType) {
							return (
								type.toLowerCase() === vm.returnType.toLowerCase() &&
								((!vm.isReturnList && !list) || (vm.isReturnList && list))
							);
						} else {
							return true;
						}
					};

					// init elements array
					vm.addColumnsToElementsArray = function () {
						resetSuggestedFunctionsList();
					};

					// Close confirmation when the user hit escape button
					vm.escConfirmation = function () {
						$scope.closeConfirmation();
					};

					vm.refreshFormulaDisplay = function () {
						$scope.selectedWidget.grammar.columnsTemp.forEach((col, idx) => {
							if (
								(col) =>
									col.formula !== undefined &&
									(col.formula.semantic_order === 1 ||
										col.formula.semantic_order === undefined)
							) {
								$scope.formulaDisplay[idx] = buildFormulaDisplayedOnList(
									col.formula
								);
							}
						});
					};

					$scope.libIsMandatory = false;
					$scope.save = function (afterDelete) {
						if (
							!afterDelete &&
							($scope.libelle == null ||
								$scope.libelle == '' ||
								$scope.formulaRoot == {} ||
								$scope.formulaRoot == undefined) &&
							($scope.isNewFormule ||
								$scope.activityFormulasChanged == true ||
								$scope.labelChanged == true)
						) {
							if ($scope.libelle == null || $scope.libelle == '') {
								toaster.pop(
									'error',
									gettextCatalog.getString(
										'Un libellé de Formule est obligatoire'
									)
								);
							}
							if ($scope.formulaRoot == {} || $scope.formulaRoot == undefined) {
								toaster.pop(
									'error',
									gettextCatalog.getString('Une Formule est obligatoire')
								);
							}
						} else if (
							$scope.formulaRoot != undefined &&
							$scope.formulaRoot != null &&
							($scope.libelle == null || $scope.libelle == '')
						) {
							$scope.libIsMandatory = true;
						} else {
							if (
								$scope.libelle != null &&
								$scope.libelle != '' &&
								$scope.formulaRoot != null &&
								$scope.formulaRoot != undefined
							) {
								$scope.addFormuleToWidget();
							}
							$scope.selectedWidget.grammar.columns = angular.copy(
								$scope.selectedWidget.grammar.columnsTemp
							);
							$scope.columnsBeforeDelete = angular.copy(
								$scope.selectedWidget.grammar.columnsTemp
							);
							if (vm.widgetData.checkMethodBeforeSaveFormulas) {
								vm.widgetData.updateaction(
									$scope.selectedWidget,
									saveAndClose,
									onDeleteFailure
								);
							} else {
								vm.widgetData.updateaction($scope.selectedWidget);
								saveAndClose();
							}
							$scope.activityFormulasChanged = false;
							$scope.enableCreatingNewFormula = true;
							$scope.hide_advanced = false;
							$scope.isDuplicateMode = false;
						}
					};

					function onDeleteFailure() {
						$scope.selectedWidget.grammar.columnsTemp =
							$scope.columnsBeforeDelete;
						$scope.selectedWidget.grammar.columns_tmp =
							$scope.columns_BeforeDelete;
					}

					function saveAndClose() {
						$scope.updateFormula = false;
						delete $scope.indiceToUpdate;
						resetSuggestedFunctionsList();
						vm.countMyFormulasOp();
						vm.refreshFormulaDisplay();
						toaster.pop(
							'success',
							gettextCatalog.getString('Succès'),
							gettextCatalog.getString('Enregistrement effectué')
						);
						$timeout(function () {
							vm.widgetData.widgetMenuData.dataUpdated = false;
						}, 500);
					}

					// TODO: check function_name
					$scope.updateFormulaStatus = function (column) {
						var elmIndex = _.findIndex(
							$scope.selectedWidget.grammar.columns,
							function (elm) {
								return elm.id === column.id;
							}
						);
						$scope.selectedWidget.grammar.columns[elmIndex].formula.active =
							!column.formula.active;

						if (
							column.formula &&
							column.formula.function_name === 'dc.ranking'
						) {
							for (var i in $scope.selectedWidget.grammar.columns) {
								if (
									$scope.selectedWidget.grammar.columns[i].parent_id ===
									column.id
								) {
									$scope.selectedWidget.grammar.columns[i].formula.active =
										$scope.selectedWidget.grammar.columns[
											elmIndex
										].formula.active;
									if (
										$scope.selectedWidget.grammar.columnsTemp &&
										$scope.selectedWidget.grammar.columnsTemp[i]
									) {
										$scope.selectedWidget.grammar.columnsTemp[
											i
										].formula.active =
											$scope.selectedWidget.grammar.columns[
												elmIndex
											].formula.active;
									}
								}
							}
						}

						vm.widgetData.updateaction($scope.selectedWidget);
						let activated = gettextCatalog.getString('formula.activation.done');
						let deactivated = gettextCatalog.getString(
							'formula.deactivation.done'
						);
						let message = $scope.selectedWidget.grammar.columns[elmIndex]
							.formula.active
							? activated
							: deactivated;
						toaster.pop('success', gettextCatalog.getString('Succès'), message);
					};

					$scope.initFormulaStatusForOldElements = function (columnId) {
						var elmIndex = _.findIndex(
							$scope.selectedWidget.grammar.columns,
							function (elm) {
								return elm.id === columnId;
							}
						);

						if (
							$scope.selectedWidget.grammar.columns[elmIndex] &&
							$scope.selectedWidget.grammar.columns[elmIndex].formula
						) {
							$scope.selectedWidget.grammar.columns[elmIndex].formula.active =
								$scope.selectedWidget.grammar.columns[elmIndex].formula
									.active === undefined
									? true
									: $scope.selectedWidget.grammar.columns[elmIndex].formula
											.active;
							if (
								$scope.selectedWidget.grammar.columnsTemp &&
								$scope.selectedWidget.grammar.columnsTemp[elmIndex]
							) {
								$scope.selectedWidget.grammar.columnsTemp[
									elmIndex
								].formula.active =
									$scope.selectedWidget.grammar.columns[
										elmIndex
									].formula.active;
							}
						}
					};

					$scope.cancel = function () {
						closeModal();
					};

					vm.loadWidgetMenu = function (widget) {
						if (angular.isFunction($scope.showWidgetMenu)) {
							$scope.showWidgetMenu($scope.save, 'formuleEditeur', widget);
						}
					};

					// Check if any changes has been made so we can tell if we should show save confirmation message
					$scope.$watch(
						'selectedWidget.grammar.columnsTemp',
						function (newValue, oldValue) {
							var newVal = _.nestedOmit(angular.copy(newValue), [
								'id',
								'$$hashKey',
								'called_formulas',
								'col',
								'distinct',
								'is_formule',
								'pivot',
								'unauthorized',
								'uca_id',
								'banalisee',
								'sort',
								'generated_col_index',
								'uca_code',
							]);
							var oldVal = _.nestedOmit(angular.copy(oldValue), [
								'id',
								'$$hashKey',
								'called_formulas',
								'col',
								'distinct',
								'is_formule',
								'pivot',
								'unauthorized',
								'uca_id',
								'banalisee',
								'sort',
								'generated_col_index',
								'uca_code',
							]);
							var initVal = _.nestedOmit(
								angular.copy($scope.initWidgetColumns),
								[
									'id',
									'$$hashKey',
									'called_formulas',
									'col',
									'distinct',
									'is_formule',
									'pivot',
									'unauthorized',
									'uca_id',
									'banalisee',
									'sort',
									'generated_col_index',
									'uca_code',
								]
							);
							if (
								!_.isEqual(newVal, oldVal) &&
								!_.isEqual(newVal, initVal) &&
								vm.widgetData &&
								vm.widgetData.widgetMenuData &&
								oldValue != undefined
							) {
								vm.widgetData.widgetMenuData.dataUpdated = true;
								$scope.activityFormulasChanged = true;
							} else {
								if (vm.widgetData && vm.widgetData.widgetMenuData) {
									vm.widgetData.widgetMenuData.dataUpdated = false;
								}
								$scope.activityFormulasChanged = false;
							}
						},
						true
					);

					// Check if any changes has been made so we can tell if we should show save confirmation message
					$scope.$watch(
						'libelle',
						function (newValue, oldValue) {
							if (
								newValue !== undefined &&
								newValue !== '' &&
								newValue !== oldValue
							) {
								$scope.labelChanged = true;
							} else {
								$scope.labelChanged = false;
							}
						},
						true
					);

					vm.closeModal = function () {
						closeModal();
					};

					$scope.showTree = function () {
						$scope.showTreeMode = true;
						$scope.showFormule = false;
					};

					$scope.showForumuInfo = function () {
						$scope.showTreeMode = false;
						$scope.showFormule = true;
					};

					$scope.insertSelectedElement = function (index) {
						let elm = $scope.elementLists[index];
						if (elm && elm.rule) {
							$scope.openRules();
						}
						if (
							elm &&
							elm.group &&
							(elm.group == $scope.functionsGroup ||
								elm.group == $scope.contsGroup)
						) {
							$scope.insertFunction(elm.id, elm.group == $scope.functionsGroup);
						} else if (
							$scope.isEnum ||
							(elm && elm.group && elm.group == $scope.dataGroup)
						) {
							$scope.insertValOrColumns(elm, elm.group == $scope.dataGroup);
						} else if ($scope.typedValueIsValid && $scope.search.value) {
							$scope.insertValOrColumns(
								{ group: 'value', label: $scope.search.value },
								false
							);
						} else {
							return;
						}
					};

					$scope.setSuggSearch = function (value) {
						$scope.suggSearchOn = value;
					};

					$scope.bindClickEventOnModal = function () {
						$scope.eventAlreadyBind = true;
						$(FORMULA_EDITOR).bind('keydown', function (event) {
							let notAction = true;
							let code = event.keyCode;
							$scope.keys.forEach(function (o) {
								if (
									o.code !== code ||
									(o.code === 13 &&
										$scope.notRootFunction() &&
										!$scope.typedValueIsValid &&
										valueInput) ||
									((code == 37 || code == 39) && !formulaTextAreaFocused)
								) {
									notAction = false;
									return;
								}
								o.action();
								event.preventDefault();
								$scope.$apply();
								notAction = true;
							});
							// go to search input with value
							if (
								!notAction &&
								formulaTextAreaFocused &&
								!$scope.labelInputFocused &&
								!$scope.descInputFocused &&
								!event.ctrlKey &&
								!event.altKey &&
								!$scope.suggSearchOn
							) {
								let chr = event.key;
								if (!chr || chr.length > 1) {
									let chrCode = code - 48 * Math.floor(code / 48);
									chr = String.fromCharCode(96 <= code ? chrCode : code);
								}
								if (chr && chr.match(/^[a-zA-Z0-9_.]*$/)) {
									focusOnSearch();
									$scope.search.value = $scope.search.value + chr.toLowerCase();
									$scope.getElementsBySearchValue($scope.search.value);
								}
							}
							// save on ctrl + s
							if (
								code == 83 &&
								event.ctrlKey &&
								!$scope.enableCreatingNewFormula
							) {
								$scope.save();
								stopEvent(event);
							}
							// delete current param ctrl + backspace
							if (code == 8 && event.ctrlKey) {
								deleteCurrentParam(event);
							}
							// focus on label ctrl + l
							if (code == 76 && event.ctrlKey) {
								focusOnLabel(event);
							}
							// copy current formula ctrl + c
							if (code == 67 && event.ctrlKey) {
								$scope.copyCurrentFormula();
							}
							// Past formula ctrl + v
							if (code == 86 && event.ctrlKey) {
								$scope.pastFormula();
							}
							// new Formula ctrl + e
							if (
								code == 69 &&
								event.ctrlKey &&
								$scope.enableCreatingNewFormula &&
								!$scope.containsAtLeastOneF
							) {
								$scope.newFormulaByClear(event);
							}
							// open rule ctrl + r
							if (
								code == 82 &&
								event.ctrlKey &&
								$scope.isTypeBoolOnly() &&
								$scope.notRootFunction() &&
								!$scope.concernedParam.isRoot
							) {
								$scope.openRules(event);
							}
							// Open formula catalogue. ctrl + k
							if (
								code == 75 &&
								event.ctrlKey &&
								!$scope.enableCreatingNewFormula &&
								$scope.formulaCatalogCount > 0
							) {
								$scope.showFormulaCatalog('formulaCatalog', event);
							}
						});
					};

					$scope.showAddFormulaToCatalog = function (modalId) {
						let formula = angular.copy($scope.formulaRoot);
						// clear enums
						clearEnumsBeforeSave(formula);
						prepareCatalogFormula(formula, '/?/');
						$scope.isNewFormula = true;
						$scope.metadataLoaded = true;
						$scope.catFormula = {
							metadata: { label: $scope.libelle },
							description: $scope.description,
							formula_str: buildFormulaDisplayed(
								formula,
								$scope.caracMode,
								ruleTra,
								$scope.selectedWidget.grammar.columns,
								$scope.elements
							),
							formula: formula,
							tags: [],
						};
						$('#' + modalId + '-' + $scope.$id).modal(
							{ backdrop: false, keyboard: false },
							'show'
						);
					};

					// TODO: Probably never used
					$scope.searchTags = function ($query) {
						return $http
							.get(API_BASE_URL_BACKEND + 'tags/?tag' + $query)
							.then(function (response) {
								return response;
							});
					};

					$scope.saveFormulaInCatalog = function (modalId) {
						for (let t in $scope.catFormula.tags) {
							$scope.catFormula.tags[t].color =
								$scope.catFormula.tags[t].color !== undefined
									? $scope.catFormula.tags[t].color
									: '#dbf5d1';
						}
						let item = {
							shared: $scope.catFormula.shared,
							metadata: $scope.catFormula.metadata,
							formula: $scope.catFormula.formula,
						};

						FormulaCatalogService.createItem(item).then(function () {
							$('#' + modalId).modal('hide');
							toaster.pop(
								'success',
								gettextCatalog.getString('Succès'),
								gettextCatalog.getString('Enregistrement effectué')
							);
							$scope.formulaCatalogElements.push(item);
							if (
								$scope.gridFormulaCatInstance &&
								$scope.gridFormulaCatInstance.option
							) {
								$scope.gridFormulaCatInstance.option(
									'dataSource',
									$scope.formulaCatalogElements
								);
								$scope.gridFormulaCatInstance.refresh();
							}
							$scope.formulaCatalogCount = $scope.formulaCatalogElements.length;
							$scope.isNewFormula = false;
							$scope.metadataLoaded = false;
						});
					};

					$scope.showFormulaCatalog = function (modalId, event) {
						stopEvent(event);
						$scope.tags = [];
						let tags = [];
						$scope.selectedFormulaCat = undefined;
						$scope.formulaCatalogElements = [];
						$scope.formulaCatalogElementsRaw = [];
						const includeShared = true;
						const activeElementsOnly = true;
						FormulaCatalogService.findAll(
							includeShared,
							activeElementsOnly
						).then(function (response) {
							prepareFormulaCatalogDataFromResp(response.data, tags);
							$('#' + modalId + '-' + $scope.$id).modal(
								{ backdrop: false, keyboard: false },
								'show'
							);
							$scope.gridFormulaCatInstance.option(
								'dataSource',
								$scope.formulaCatalogElements
							);
						});
					};

					$scope.getTypeHtmlDisp = function (type, isList) {
						if (type != undefined) {
							type = type.toLowerCase();
							return $sce.trustAsHtml(getHtmlIconByType(type, isList, GrammarUtils.getTypeLabel(type)));
						} else {
							return '';
						}
					};

					$scope.getTagBox = function (list) {
						$scope.tagBox.value = list.data.tags;
						return $scope.tagBox;
					};

					$scope.updateSharedFormulaStatus = function (elm) {
						FormulaCatalogService.updateSharedStatus(elm.id, elm.shared)
							.then(function (response) {
								elm = response.data;
								toaster.pop(
									'success',
									gettextCatalog.getString('Succès'),
									gettextCatalog.getString('Enregistrement effectué')
								);
							})
							.catch(function (e) {
								elm.shared = !elm.shared;
							});
					};

					$scope.assignFormula = function (modalId) {
						prepareCatalogFormula($scope.selectedFormulaCat.formula, '');
						$scope.insertFunction(undefined, true, true);
						$scope.libelle = !$scope.libelle
							? $scope.selectedFormulaCat.label
							: $scope.libelle;
						$('#' + modalId).modal('hide');
					};

					$scope.showDeleteFormulaFromCatalog = function (modalId, item) {
						$scope.catalogFormulaItemToDelete = item;
						$('#' + modalId + '-' + $scope.$id).modal(
							{ backdrop: false, keyboard: false },
							'show'
						);
					};

					$scope.deleteCatalogFormula = function (modalId) {
						FormulaCatalogService.deleteItem(
							$scope.catalogFormulaItemToDelete.id
						).then(function () {
							$('#' + modalId).modal('hide');
							for (let i in $scope.formulaCatalogElements) {
								if (
									$scope.formulaCatalogElements[i].id ==
									$scope.catalogFormulaItemToDelete.id
								) {
									$scope.formulaCatalogElements.splice(i, 1);
									break;
								}
							}
							if (
								$scope.gridFormulaCatInstance &&
								$scope.gridFormulaCatInstance.option
							) {
								$scope.gridFormulaCatInstance.option(
									'dataSource',
									$scope.formulaCatalogElements
								);
							}
							$scope.formulaCatalogCount = $scope.formulaCatalogElements.length;
							toaster.pop(
								'success',
								gettextCatalog.getString('Succès'),
								gettextCatalog.getString('Suppression effectuée')
							);
						});
					};

					$scope.copyCurrentFormula = function () {
						if ($scope.concernedParam) {
							$scope.copiedToClipboard = true;
							$scope.copyError = false;
							if (
								!$scope.concernedParam ||
								($scope.concernedParam.type == 'function' &&
									$scope.concernedParam.function_name == 'ARGS')
							) {
								toaster.pop('error', unsupportedAction);
								$scope.copyError = true;
								$timeout(function () {
									$scope.copiedToClipboard = false;
								}, 600);
								return;
							}
							let clipboard = angular.copy($scope.concernedParam);
							delete clipboard.isRoot;
							$scope.clipboard = angular.copy(clipboard);
							$scope.clipboardDisplay = buildFormulaDisplayedOnList(
								$scope.clipboard
							);
							let isList = false;
							if (parentFunction) {
								let elm = _.find($scope.elements, function (e) {
									return (
										(e.label === parentFunction.object.function_code || e.label === parentFunction.object.function_name) &&
										(e.group === $scope.functionsGroup ||
											e.group === $scope.contsGroup)
									);
								});
								isList = elm
									? elm.input[parentFunction.index].is_typed_list
									: false;
							} else if (
								$scope.clipboard.type === 'function' ||
								$scope.clipboard.type === 'constant'
							) {
								let elm = _.find($scope.elements, function (e) {
									return (
										(e.label === $scope.clipboard.function_code || e.label === $scope.clipboard.function_name) &&
										(e.group === $scope.functionsGroup ||
											e.group === $scope.contsGroup)
									);
								});
								isList = elm && elm.response && elm.response.list;
							} else if ($scope.clipboard.type == 'column') {
								isList = getColumnList($scope.clipboard.value);
							}
							$scope.clipboard.isList = isList ? isList : false;
							if ($scope.clipboard.function_name === "dc.null" || $scope.clipboard.function_code === "dc.null") {
								$scope.clipboard.isGenericTypeFormula = true;
							}
							$timeout(function () {
								$scope.copiedToClipboard = false;
							}, 600);
						}
					};

					$scope.pastFormula = function () {
						if ($scope.clipboard) {
							if (
								($scope.isRootFunction &&
									$scope.clipboard.type != 'function' &&
									$scope.clipboard.type != 'constant') ||
								($scope.isEnum != $scope.clipboard.type) == 'enum' ||
								(!$scope.clipboard.isGenericTypeFormula && !$scope.isRootFunction &&
									$scope.paramAcceptedTypes.indexOf(
										$scope.clipboard.data_type
									) < 0)
							) {
								toaster.pop('error', incompatibleType);
								return;
							}
							if ($scope.concernedParam) {
								if (
									!$scope.concernedParam ||
									($scope.concernedParam.type == 'function' &&
										$scope.concernedParam.function_name == 'ARGS')
								) {
									toaster.pop('error', unsupportedAction);
									return;
								}
							}
							if (parentFunction) {
								let elm = _.find($scope.elements, function (e) {
									return (
										e.label === parentFunction.object.function_name &&
										(e.group === $scope.functionsGroup ||
											e.group === $scope.contsGroup)
									);
								});
								let isList = elm
									? elm.input[parentFunction.index].is_typed_list
									: false;
								isList = isList ? isList : false;
								if ($scope.clipboard.isList != isList) {
									toaster.pop('error', incompatibleType);
									return;
								}
								if ($scope.isEnum) {
									let enums = elm ? elm.input[parentFunction.index].enums : [];
									let foundEnum = _.find(enums, function (e) {
										return e.value === $scope.clipboard.value;
									});
									if (!foundEnum) {
										toaster.pop('error', incompatibleType);
										return;
									}
								}
							}
							$scope.selectedFormulaCat = {
								formula: angular.copy($scope.clipboard),
							};
							$scope.insertFunction(undefined, true, true);
						}
					};

					$scope.isTypeBoolOnly = function () {
						return (
							$scope.paramAcceptedTypes &&
							$scope.paramAcceptedTypes.length == 1 &&
							$scope.paramAcceptedTypes[0] == 'boolean'
						);
					};

					$scope.getFormulaCatalogCountAndShow = function (element) {
						$scope.enableFormulaCatalog =
							$rootScope.havePermission('formula_catalog');
						if ($scope.enableFormulaCatalog) {
							FormulaCatalogService.countAll().then(function (response) {
								$scope.formulaCatalogCount = response.data;
								$(element).modal(
									{ backdrop: 'static', keyboard: false },
									'show'
								);
							});
						} else {
							$(element).modal({ backdrop: 'static', keyboard: false }, 'show');
						}
					};

					$scope.showExportFormulaPopup = function (formulaId) {
						$scope.exportFormulaData = {
							selectedElems: [formulaId],
							elements: $scope.formulaCatalogElementsRaw,
						};
						$scope.showExportPopup($scope.exportFormulaData, false);
					};

					$scope.showExportFormulaPopupMultiple = function () {
						$scope.exportFormulaData = {
							selectedElems: selectedElms,
							elements: $scope.formulaCatalogElementsRaw,
						};
						$scope.showExportPopup($scope.exportFormulaData, true);
					};

					$scope.showImportFormulaPopup = function () {
						$scope.showImportPopup();
					};

					$scope.refreshListAfterImport = function (allFormulas) {
						let data = $scope.formulaCatalogElementsRaw.concat(allFormulas);
						prepareFormulaCatalogDataFromResp(data, $scope.tags);
						$scope.gridFormulaCatInstance.option(
							'dataSource',
							$scope.formulaCatalogElements
						);
						$scope.gridFormulaCatInstance.refresh();
					};

					/*
					 * Private functions
					 */

					function clearEnumsBeforeSave(formula) {
						delete formula.enums;
						if (formula.params && formula.params.length > 0) {
							formula.params.forEach((fo) => clearEnumsBeforeSave(fo));
						}
					}

					$scope.getFormulaGeneratedToolTip = function (col) {
						const caracMode = false;
						const styled = true;
						let tooltipText =
							'<p class="formula-tooltip">' +
							buildFormulaDisplayed(
								col.formula,
								caracMode,
								ruleTra,
								$scope.selectedWidget.grammar.columns,
								$scope.elements,
								styled
							) +
							'</p>';
						return {
							target: '#ViewFormulaShortCut-' + col.id,
							showEvent: 'mouseenter',
							hideEvent: 'mouseleave',
							maxWidth: '50%',
							closeOnOutsideClick: false,
							contentTemplate: function (data) {
								data.html(tooltipText);

							},
						};
					};

					function generateFormulaForInsert(isFormula, index) {
						// FIXME: instead of sometimes sending index from the view, send formula or constant
						var selected = isFormula
							? jsonResponse.formule[index]
							: jsonResponse.constant[index];

						var dataType = selected.response
							? selected.response.type
							: selected.type
							? selected.type
							: 'string';

						// newly inserted function from the view, using label instead of identity field
						var formula = {
							function_name: isFormula
								? selected.ident // formulas
								: selected.label, // constants
							function_code: selected.code,
							params: [],
							type: isFormula ? 'function' : 'constant',
							data_type: dataType,
						};

						var generatedString = selected.label + '(';
						var generatedInputs = [];
						var formulaArgs = selected.input;
						if (isFormula) {
							for (var p = 0; p < formulaArgs.length; p++) {
								var input = {
									function_name: null,
									params: [],
									type: 'value',
									value: '',
									data_type: formulaArgs[p].type[0],
									supportedTypes: formulaArgs[p].type,
								};
								if (
									formulaArgs[p].enums != undefined &&
									formulaArgs[p].enums.length > 0
								) {
									input.type = 'enum';
									input.enums = formulaArgs[p].enums;
								}
								if (formulaArgs[p].is_list) {
									input = generateListParamsObject();
								}

								if (p < formulaArgs - 1) {
									generatedString = generatedString + ' ,';
								} else {
									generatedString = generatedString + ' ';
								}
								//input.end_position = generatedString.length;
								generatedInputs.push(input);
							}

							if (formulaArgs.length == 0) {
								var emptyInput = {
									function_name: null,
									params: [],
									type: 'value',
									value: '',
								};
								generatedInputs.push(emptyInput);
							}
						}
						generatedString = generatedString + ')';
						formula.end_position = generatedString.length;
						formula.params = generatedInputs;

						return formula;
					}

					function getFormulasAndConstantsList() {
						let elements = [];

						var queryObject = {
							label: '',
							group: $scope.valuesGroup,
							query: true,
						};

						elements.push(queryObject);

						for (var f = 0; f < jsonResponse.formule.length; f++) {
							jsonResponse.formule[f].id = f;
							var func = jsonResponse.formule[f];
							func.group = $scope.functionsGroup;
							elements.push(func);
						}

						for (var c = 0; c < jsonResponse.constant.length; c++) {
							jsonResponse.constant[c].id = c;
							var cons = jsonResponse.constant[c];
							cons.group = $scope.contsGroup;
							elements.push(cons);
						}

						return elements;
					}

					// function getAllParams(params, listParams) {
					// 	if (params) {
					// 		for (var i = 0; i < params.length; i++) {
					// 			if (params[i].is_list) {
					// 				getAllParams(params[i].params, listParams);
					// 			} else {
					// 				listParams.push(params[i]);
					// 			}
					// 		}
					// 	}
					// }

					function getColumnType(col) {
						if (vm.modes && vm.modes.refByLabel) {
							let foundCol = _.find(
								$scope.selectedWidget.grammar.columns,
								function (elm) {
									return elm.column_alias === col || elm.field === col;
								}
							);
							return foundCol && foundCol.type ? foundCol.type : undefined;
						}
						var labelIndex = col.replace('COLL', '');
						return $scope.selectedWidget.grammar.columns[labelIndex] &&
							$scope.selectedWidget.grammar.columns[labelIndex].type !=
								undefined
							? $scope.selectedWidget.grammar.columns[labelIndex].type
							: undefined;
					}

					function getColumnList(col) {
						if (vm.modes && vm.modes.refByLabel) {
							let foundCol = _.find(
								$scope.selectedWidget.grammar.columns,
								function (elm) {
									return elm.column_alias === col || elm.field === col;
								}
							);
							return foundCol && foundCol.list ? foundCol.list : false;
						}
						var labelIndex = col.replace('COLL', '');
						return $scope.selectedWidget.grammar.columns[labelIndex] &&
							$scope.selectedWidget.grammar.columns[labelIndex].list !=
								undefined
							? $scope.selectedWidget.grammar.columns[labelIndex].list
							: false;
					}

					// Return column name as COLL'index' for a given label
					function getCorrespondingCol(label) {
						if (vm.modes && vm.modes.refByLabel) {
							return label;
						}
						var column = null;
						for (
							var c = 0;
							c < $scope.selectedWidget.grammar.columns.length;
							c++
						) {
							if (
								$scope.selectedWidget.grammar.columns[c].column_alias == label
							) {
								column = 'COLL' + c;
							}
						}
						return column != null ? column : label;
					}

					// Return enum value for a given label
					function getCorrespondingEnumValue(label, enums) {
						if (label == undefined || enums == undefined || label == '') {
							return label != undefined ? label : '';
						}
						var value = label;
						for (var c = 0; c < enums.length; c++) {
							if (enums[c].label == label) {
								value = enums[c].value;
								break;
							}
						}

						return value;
					}

					// Get the LIST function element
					function getListFunction() {
						var listF = null;
						for (var c = 0; c < $scope.elements.length; c++) {
							if (
								$scope.elements[c].group === $scope.functionsGroup &&
								$scope.elements[c].label.toUpperCase() === 'ARGS'
							) {
								listF = $scope.elements[c];
								break;
							}
						}

						return listF;
					}

					// Generate a function list with  defaults params
					function generateListParamsObject() {
						var generatedObject = {
							function_name: null,
							params: [],
							type: 'value',
							value: '',
						};

						var listF = getListFunction();
						if (listF != null) {
							generatedObject.function_name = listF.label;
							generatedObject.function_code = listF.code;
							generatedObject.type = 'function';
							for (var p = 0; p < listF.input.length; p++) {
								var input = {
									function_name: null,
									params: [],
									type: 'value',
									value: '',
								};
								generatedObject.params.push(input);
							}

							return generatedObject;
						} else {
							return generatedObject;
						}
					}

					// FIXME: Generate formula expression from the formula object using formula_name which is now mapped on
					//  identity, this function is useless
					function buildFormulaStr(formula) {
						if (!formula) {
							return '';
						}
						var functionStr = formula.function_name + '(';
						for (var l = 0; l < formula.params.length; l++) {
							if (formula.params[l].function_name === null) {
								if (
									formula.params[l].type === 'value' ||
									formula.params[l].type === 'enum'
								) {
									functionStr =
										functionStr + "'" + formula.params[l].value + "'";
								} else {
									functionStr = functionStr + formula.params[l].value;
								}
							} else {
								functionStr = functionStr + buildFormulaStr(formula.params[l]);
							}
							if (l < formula.params.length - 1) {
								functionStr = functionStr + ', ';
							}
						}

						functionStr = functionStr + ')';

						return functionStr;
					}

					// Call the build function for the ui
					function buildFormulaDisplayedOnList(formula) {
						const styled = true;
						const formulaTxt = buildFormulaDisplayed(
							formula,
							$scope.caracMode,
							ruleTra,
							$scope.selectedWidget.grammar.columns,
							$scope.elements,
							styled
						);

						return $sce.trustAsHtml(formulaTxt);
					}

					// set id to undefined
					function resetIds(formula) {
						if (!formula) {
							return;
						}
						formula.id = undefined;
						for (var w = 0; w < formula.params.length; w++) {
							formula.params[w].id = undefined;
							if (
								formula.params[w].function_name === null ||
								formula.params[w].function_name === ''
							) {
								formula.params[w].id = undefined;
							} else {
								resetIds(formula.params[w]);
							}
						}
					}

					function UserException(message) {
						this.message = message;
						this.name = 'UserException';
					}

					function getUsedFormule(funcName) {
						if (funcName === undefined) {
							return null;
						}

						let used = $window.dcFormulasByIdentifer[funcName] || null;

						if (used === null) {
							// FIXME: use maps for constants
							// lookup in const
							const funcNameUpper = funcName.toUpperCase();
							used = _.find(jsonResponse.constant, constant => {
								const label = constant.label ? constant.label.toUpperCase() : undefined;
								return label === funcNameUpper;
							});
						}
						return used;
					}

					function buildFormulaObject(keepListOn) {
						resetIds($scope.formulaRoot);
						$scope.functionString = buildFormulaDisplayed(
							$scope.formulaRoot,
							$scope.caracMode,
							ruleTra,
							$scope.selectedWidget.grammar.columns,
							$scope.elements
						);
						if (!keepListOn) {
							// close and clear suggestions
							$scope.search.value = '';
							$(FORMULA_SUGGESTIONS).modal('hide');
						}
					}

					function saveFilterForRuleFormula(rules) {
						recurseFilterDatesToIsoDefaultTz(rules);
						$scope.insertValOrColumns(null, false, rules);
						$scope.suggSearchOn = false;
					}

					function recurseFilterDatesToIsoDefaultTz(rules) {
						if (rules.condition) {
							recurseFilterDatesToIsoDefaultTz(rules.rules);
						} else {
							DataBlocksService.filterDatesToIsoDefaultTz(rules);
						}
					}

					function deleteRefresh(startPosition) {
						buildFormulaObject();
						$scope.getElementsBySearchValue($scope.search.value);
						// go to position
						goToPositionAndAfterFocus(startPosition, true, true);
					}

					// Delete object and hide suggestions list
					function deleteCurrentParam(event) {
						stopEvent(event);
						if (!$scope.concernedParam || !$scope.formulaRoot) {
							return;
						}
						if (
							$scope.concernedParam &&
							$scope.concernedParam.type === 'function' &&
							$scope.concernedParam.function_name === 'ARGS'
						) {
							toaster.pop('error', unsupportedAction);
							return;
						}
						let startPosition = angular.copy(
							$scope.concernedParam.start_position
						);
						if ($scope.concernedParam.isRoot || startPosition === 0) {
							$scope.formulaRoot = null;
							deleteRefresh(startPosition);
							return;
						}
						$scope.search.value = '';
						var updatedParam = {
							function_name: null,
							params: [],
							type: $scope.isEnum ? 'enum' : 'value',
							value: $scope.search.value,
						};
						if ($scope.isEnum) {
							updatedParam.enums = $scope.enums;
						}
						replaceParam(
							$scope.formulaRoot,
							$scope.concernedParam.id,
							updatedParam
						);
						deleteRefresh(startPosition);
					}

					// Get the selected param
					function getParamObject(object, selectedPosition) {
						var potentielPrams = getConcernedParams(object, selectedPosition);
						var nearestObject = potentielPrams[0];
						for (var t = 0; t < potentielPrams.length; t++) {
							if (
								nearestObject.start_position - selectedPosition <=
								potentielPrams[t].start_position - selectedPosition
							) {
								nearestObject = potentielPrams[t];
							}
						}

						return nearestObject;
					}

					// Get params that intersected with selected position
					function getConcernedParams(object, selectedPosition) {
						var objectsList = [];
						if (
							object.start_position <= selectedPosition &&
							selectedPosition <= object.end_position
						) {
							objectsList.push(object);
						}
						for (var j = 0; j < object.params.length; j++) {
							if (
								object.params[j].start_position <= selectedPosition &&
								selectedPosition <= object.params[j].end_position
							) {
								objectsList.push(object.params[j]);
							}
							if (object.params[j].params.length > 0) {
								var subList = getConcernedParams(
									object.params[j],
									selectedPosition
								);
								for (var y = 0; y < subList.length; y++) {
									objectsList.push(subList[y]);
								}
							}
						}

						return objectsList;
					}

					// Replace paras (input)
					function replaceParam(root, objectId, newParam) {
						if (!root) {
							return true;
						}
						if (root.id == objectId) {
							root = newParam;
							root.id = undefined;
							return true;
						} else {
							for (var t = 0; t < root.params.length; t++) {
								if (root.params[t].id == objectId) {
									root.params[t] = newParam;
									return true;
								}
								if (root.params[t].params.length > 0) {
									let stop = replaceParam(root.params[t], objectId, newParam);
									if (stop) {
										break;
									}
								}
							}
						}
					}

					// Get parent function for a given param
					function getFunctionForParam(param, rootObject) {
						var results = undefined;
						if (
							(param != undefined && rootObject != undefined) ||
							(param != null && rootObject != null)
						) {
							var params = rootObject.params;
							for (var t = 0; t < params.length; t++) {
								if (param.id == params[t].id) {
									results = { object: rootObject, index: t };
									break;
								}
								if (params[t].params.length > 0) {
									results = getFunctionForParam(param, params[t]);
								}
								if (results != undefined) {
									break;
								}
							}
						}

						return results;
					}

					// update list args when add or delete one
					function updateListArgs() {
						if (
							$scope.isFunction &&
							$scope.concernedParam != null &&
							$scope.concernedParam != undefined
						) {
							var listOldParamsNumber = $scope.concernedParam.params.length;
							var argDiff = Math.abs(listOldParamsNumber - $scope.argsNumber);
							if (listOldParamsNumber < $scope.argsNumber) {
								for (var p = 0; p < argDiff; p++) {
									var emptyInput = {
										function_name: null,
										params: [],
										type: 'value',
										value: '',
									};

									$scope.concernedParam.params.push(emptyInput);
								}
							} else {
								for (var r = 0; r < argDiff; r++) {
									if ($scope.concernedParam.params.length > MIN_ARGS) {
										$scope.concernedParam.params.splice(-1, 1);
									}
								}
							}

							if (argDiff != 0) {
								replaceParam(
									$scope.formulaRoot,
									$scope.concernedParam.id,
									$scope.concernedParam
								);
							}
						}

						buildFormulaObject(true);
					}

					function getShowFunctionDetailsData(
						elm,
						index,
						realParamSize,
						typesFiltered
					) {
						if (elm != undefined) {
							$scope.showFunctionDetails = true;
							$scope.functionDetails = {
								name: '',
								params: [],
							};
							$scope.functionDetails.name = elm.label;
							$scope.functionDetails.name =
								elm.description != ''
									? elm.label + ' : ' + elm.description
									: $scope.functionDetails.name;
							if (realParamSize != elm.input.length) {
								var diff = Math.abs(realParamSize - elm.input.length);
								if (realParamSize > elm.input.length) {
									for (var b = 0; b < diff; b++) {
										let newElm = angular.copy(elm.input[elm.input.length - 1]);
										newElm.label =
											gettextCatalog.getString('valeur') +
											' ' +
											(elm.input.length + 1);
										newElm.description =
											gettextCatalog.getString('valeur') +
											' ' +
											(elm.input.length + 1);
										elm.input[elm.input.length] = newElm;
									}
								} else {
									elm.input.splice(-1, 1);
								}
							}
							for (var t = 0; t < elm.input.length; t++) {
								$scope.functionDetails.params[t] = {
									label: elm.input[t].label,
									description: elm.input[t].description,
									selected: t == index,
									types: [],
								};
								// if rule build rule data display
								if ($scope.functionDetails.params[t].selected && $scope.concernedParam && $scope.concernedParam.is_rule) {
									let ruleTxt = new StringBuffer();
									getTextFromRule(ruleTxt, $scope.concernedParam.rule, $scope.selectedWidget.grammar.columns);
									const ruleTxtAsHtml = "<span title='" + ruleTxt + "'><strong>" + ruleDefTra + "</u></i></strong></span> [ " + ruleTxt + " ]";
									$scope.functionDetails.params[t].isRule = true;
									$scope.functionDetails.params[t].ruleHtml = $sce.trustAsHtml(ruleTxtAsHtml);
									$scope.functionDetails.selectedIndex = t;
								}
								var types = [];
								var typesCode = [];
								if (elm.label == 'ARGS' && typesFiltered) {
									typesCode = typesFiltered;
								} else {
									typesCode = elm.input[t].type;
								}
								typesCode.forEach(function (element) {
									types.push($scope.getTypeLabel(element, elm.input[t].list));
								});
								$scope.functionDetails.params[t].types = $sce.trustAsHtml(
									' (' + types.toString() + ')'
								);
							}
						}
					}

					function resetSuggestedFunctionsList() {
						$scope.elements = getFormulasAndConstantsList();
						for (
							var d = 0;
							d < $scope.selectedWidget.grammar.columns.length;
							d++
						) {
							if ($scope.indiceToUpdate != d) {
								var data = $scope.selectedWidget.grammar.columns[d];
								if (!data.formula) {
									data.sql_alias = getSqlAlias(
										$scope.selectedWidget.grammar.columns,
										d
									);
								}

								data.group = $scope.dataGroup;
								data.label = data.column_alias;
								$scope.elements.push(data);
							}
						}
					}

					function getSqlAlias(columns, index) {
						if (columns[index].uuid) {
							return 'COL_' + columns[index].uuid;
						}
						if (!columns[index].path) {
							return undefined;
						}

						if (columns[index].pivot) {
							return columns[index].uca_code;
						}

						var pathElements = columns[index].path.split('-');
						if (pathElements.length < 4) {
							return columns[index].uca_code;
						}

						return (
							'`' +
							pathElements.slice(0, pathElements.length - 1).join('-') +
							'`.' +
							columns[index].uca_code
						);
					}

					function generateMapByLabel(formulas) {
						let obj = {};
						return formulas.reduce((acc, curr) => {
							const fNameLabel = curr.label;
							acc[fNameLabel] = curr;
							return acc;
						}, {});
					}

					function generateMapByIdentifier(formulas) {
						let obj = {};
						return formulas.reduce((acc, curr) => {
							const fId = curr.ident;
							acc[fId] = curr;
							return acc;
						}, {});
					}

					function getAvailableTags() {
						return new Promise(function (resolve) {
							setTimeout(function () {
								resolve(
									_.map(
										_.sortBy($scope.tags, function (i) {
											return i.label.toLowerCase();
										}),
										function (el) {
											return el.label;
										}
									)
								);
							}, 50);
						});
					}

					function goToPositionAndAfterFocus(position, focus, higherTimeOut) {
						let timeoutVal = higherTimeOut ? 160 : 110;
						setTimeout(function () {
							let inc = position ? position : 0;
							angular.element(FORMULA_TEXT_AREA)[0].selectionStart = inc;
							angular.element(FORMULA_TEXT_AREA)[0].selectionEnd = inc;
							if (focus) {
								$(FORMULA_TEXT_AREA).focus();
							}
						}, timeoutVal);
					}

					function closeModal() {
						$scope.clearFormula(false);
						$(vm.element).modal('hide');
						vm.widgetData.showFormule = false;
						$scope.impact = 0;
						$scope.elements = [];
						vm.returnType = undefined;
						var queryObject = {
							label: '',
							group: $scope.valuesGroup,
							query: true,
						};
						$scope.elements.push(queryObject);
						if ($scope.vm.widgetData.widgetMenuData) {
							$scope.vm.widgetData.widgetMenuData.showWidgetMenu = false;
						}
					}

					function isListCreate(label) {
						return label && label.includes('.list.create') && label.startsWith('deprecated.');
					}

					// Get the selected param
					function getParamByStartPosition(selectedPosition) {
						return getParamObject($scope.formulaRoot, selectedPosition);
					}

					// Get last Param
					function getLastParam(formula) {
						let result = formula;
						if (formula && formula.params && formula.params.length > 0) {
							result = getLastParam(formula.params[formula.params.length - 1]);
						}

						return result;
					}

					function moveTo(direction) {
						if (!$scope.functionString || $scope.suggSearchOn) {
							return;
						}
						setTimeout(function () {
							$(FORMULA_TEXT_AREA).focus();
						}, 50);
						let p = parentFunction;
						let selectionPos = $scope.selectionStartPos;
						let cp = $scope.concernedParam;
						let root = $scope.isRootFunction;
						let start = 1;
						let end = 1;
						let lookForParam = false;
						let step = 1;
						let index = p ? p.index : undefined;
						if (direction === 'left' && p && index >= 1) {
							index = index - 1;
							let lastElem = p.object;
							if (lastElem && lastElem.params && lastElem.params.length > 0) {
								lastElem = lastElem.params[index];
								let last = getLastParam(lastElem);
								if (last) {
									start = last.start_position;
									end = last.end_position;
								} else {
									start = lastElem.start_position;
									end = lastElem.end_position;
								}
							}
						} else if (cp) {
							if (cp.params && cp.params.length > 0 && direction === 'right') {
								index = 0;
								let param = cp.params[index];
								start = param.start_position;
								end = param.end_position;
							} else {
								start = cp.start_position;
								end = cp.end_position;
								lookForParam = true;
								let ids = '' + cp.id;
								ids = ids.split('_');
								step = ids[ids.length - 1].length;
							}
						} else {
							if (
								root &&
								(selectionPos == 0 ||
									selectionPos == $scope.formulaRoot.end_position)
							) {
								if (
									$scope.formulaRoot.params &&
									$scope.formulaRoot.params.length > 0
								) {
									index =
										direction === 'left'
											? 0
											: $scope.formulaRoot.params.length - 1;
									let param = $scope.formulaRoot.params[index];
									start = param.start_position;
									end = param.end_position;
								}
							} else {
								start = $scope.selectionStartPos;
								end = $scope.selectionStartPos;
							}
						}
						step = step != undefined ? step : 1;
						let lookedUpParam = lookForParam
							? getParamByStartPosition(
									direction === 'left' ? start - step : end + step
							  )
							: undefined;
						if (lookedUpParam) {
							angular.element(FORMULA_TEXT_AREA)[0].selectionEnd =
								lookedUpParam.start_position;
							angular.element(FORMULA_TEXT_AREA)[0].selectionStart =
								lookedUpParam.start_position;
							$scope.openSuggestionsModal();
						} else {
							angular.element(FORMULA_TEXT_AREA)[0].selectionEnd = start;
							angular.element(FORMULA_TEXT_AREA)[0].selectionStart = start;
							$scope.openSuggestionsModal();
						}
					}

					function focusOnSearch() {
						setTimeout(function () {
							$(FORMULA_SUGGESTIONS_SEARCH).focus();
							$(FORMULA_TEXT_AREA).blur();
							formulaTextAreaFocused = false;
						}, 50);
					}

					function focusOnFormulaArea() {
						formulaTextAreaFocused = true;
						setTimeout(function () {
							$(FORMULA_SUGGESTIONS_SEARCH).blur();
							$(FORMULA_TEXT_AREA).focus();
						}, 50);
					}

					function focusOnFormulaAreaPos0() {
						formulaTextAreaFocused = true;
						setTimeout(function () {
							angular.element(FORMULA_TEXT_AREA)[0].selectionEnd = 0;
							angular.element(FORMULA_TEXT_AREA)[0].selectionStart = 0;
							$(FORMULA_SUGGESTIONS_SEARCH).blur();
							$(FORMULA_TEXT_AREA).focus();
							focusOnFormulaArea();
						}, 50);
					}

					let formulaTextAreaFocused = true;
					function focusSwitch() {
						if (formulaTextAreaFocused) {
							focusOnSearch();
						} else {
							focusOnFormulaArea();
						}
					}

					function scrollTo() {
						if (!$('.selected-formula-element:first')[0]) {
							return;
						}
						let off = $('.selected-formula-element:first')[0].offsetTop;
						$('.selected-formula-element:first')[0].focus();
						let ch = $('#suggestionsList')[0].clientHeight;
						let scrollTop = off - ch;
						$('#suggestionsList')[0].scrollTop =
							scrollTop +
							($scope.focusIndex == $scope.elementsF.length ? 0 : 60);
					}

					function cleanSuggestions() {
						$scope.elmDetails = false;
						$scope.paramsDesciption = [];
						$scope.elemName = '';
						$scope.cancelParamsDetailsAction();
						$scope.formulaTestFocused = false;
						$scope.showSuggestions = false;
					}

					function prepareFormulaCatalogDataFromResp(data, tags) {
						$scope.formulaCatalogElementsRaw = data;
						for (let i in $scope.formulaCatalogElementsRaw) {
							if (
								!$scope.paramAcceptedTypes ||
								$scope.paramAcceptedTypes.length == 0 ||
								$scope.paramAcceptedTypes.indexOf(
									$scope.formulaCatalogElementsRaw[
										i
									].formula.data_type.toLowerCase()
								) >= 0
							) {
								$scope.formulaCatalogElements[i] = {
									id: $scope.formulaCatalogElementsRaw[i].id,
									type: $scope.formulaCatalogElementsRaw[i].formula.data_type,
									label: $scope.formulaCatalogElementsRaw[i].metadata.label,
									description:
										$scope.formulaCatalogElementsRaw[i].metadata.description,
									tags: $scope.formulaCatalogElementsRaw[i].metadata.tags,
									created:
										$scope.formulaCatalogElementsRaw[i].metadata.creation_date,
									created_by:
										$scope.formulaCatalogElementsRaw[i].metadata.created_by,
									formula: $scope.formulaCatalogElementsRaw[i].formula,
									shared: $scope.formulaCatalogElementsRaw[i].shared,
									tagAsArray: $rootScope.mapTags(
										$scope.formulaCatalogElementsRaw[i].metadata.tags
									),
								};
								tags = _.union(
									tags,
									$scope.formulaCatalogElementsRaw[i].metadata.tags
								);
							}
						}
						for (let i in tags) {
							if (_.find($scope.tags, { id: tags[i].id }) == null) {
								$scope.tags.push({ id: tags[i].id, label: tags[i].code });
							}
						}
					}

					function checkIfFormulaUsedInColumnsByIndex(column, id) {
						var isUsed = false;
						for (var i in column.params) {
							if (column.params[i].type == 'function') {
								isUsed = checkIfFormulaUsedInColumnsByIndex(
									column.params[i],
									id
								);
							} else if (column.params[i].type == 'column') {
								isUsed = column.params[i].value == 'COLL' + id;
							}
							if (isUsed) {
								return isUsed;
							}
						}

						return isUsed;
					}

					function checkIfFormulaUsedInColumnsByLabel(column, Label) {
						var isUsed = false;
						for (var i in column.params) {
							if (column.params[i].type == 'function') {
								isUsed = checkIfFormulaUsedInColumnsByIndex(
									column.params[i],
									id
								);
							} else if (column.params[i].type == 'column') {
								isUsed = column.params[i].value == Label;
							}
							if (isUsed) {
								return isUsed;
							}
						}

						return isUsed;
					}

					// update sub formulas indexes
					function updateSubFormulaIndexes(formula, oldIds) {
						if (formula.type == 'function' && formula.params) {
							for (var p in formula.params) {
								updateSubFormulaIndexes(formula.params[p], oldIds);
							}
						} else if (formula.type == 'column') {
							if (oldIds.hasOwnProperty(formula.value.toUpperCase())) {
								formula.value = oldIds[formula.value.toUpperCase()];
							}
						}
					}

					function validateTypingValue(elements) {
						$scope.typedValueIsValid = false;
						$scope.invalidDateValue = false;
						let queryElm = _.find(elements, function (elm) {
							return elm.group == $scope.valuesGroup && elm.query;
						});
						if (queryElm && queryElm.label) {
							for (let i in $scope.paramAcceptedTypes) {
								if ($scope.paramAcceptedTypes[i] == 'string') {
									$scope.typedValueIsValid = true;
									typedValueType = 'string';
									break;
								} else if ($scope.paramAcceptedTypes[i] == 'decimal') {
									$scope.typedValueIsValid = !isNaN(
										parseFloat(queryElm.label.match(/^-?\d*(\.\d+)?$/))
									);
									if ($scope.typedValueIsValid) {
										typedValueType = 'decimal';
										break;
									}
								} else if ($scope.paramAcceptedTypes[i] == 'integer') {
									let x = +queryElm.label;
									$scope.typedValueIsValid =
										Number.isInteger(x) &&
										!queryElm.label.startsWith('.') &&
										!queryElm.label.startsWith('.') &&
										!queryElm.label.endsWith('.');
									if ($scope.typedValueIsValid) {
										typedValueType = 'integer';
										break;
									}
								} else if ($scope.paramAcceptedTypes[i] == 'big_integer') {
									let x = +queryElm.label;
									$scope.typedValueIsValid =
										(Number.isInteger(x) ||
											(!Number.isNaN(x) && !Number.isSafeInteger(x))) &&
										!queryElm.label.startsWith('.') &&
										!queryElm.label.endsWith('.');
									if ($scope.typedValueIsValid) {
										typedValueType = 'big_integer';
										break;
									}
								} else if ($scope.paramAcceptedTypes[i] == 'date') {
									$scope.typedValueIsValid =
										moment(queryElm.label, 'YYYY-MM-DD', true).isValid() ||
										moment(
											queryElm.label,
											'YYYY-MM-DD HH:mm:ss.SSS',
											true
										).isValid() ||
										moment(
											queryElm.label,
											'YYYY-MM-DD HH:mm:ss',
											true
										).isValid();
									if ($scope.typedValueIsValid) {
										typedValueType = 'date';
										break;
									} else if ($scope.paramAcceptedTypes.length == 1) {
										$scope.invalidDateValue = true;
									}
								}
							}
						}
					}

					function getSearchValueFromConcernedParam(concernedParam) {
						if (!concernedParam) {
							return null;
						}
						if (concernedParam.type == 'function') {
							return getFormulaDisplayName(concernedParam.function_name, false);
						}

						return $scope.concernedParam.function_name;
					}
				},
			];

			return {
				restrict: 'E',

				scope: {
					widgetData: '=',
					data: '=',
					onlyOneFormula: '=',
					modes: '=',
				},
				controller: controller,
				controllerAs: 'vm',
				bindToController: true,
				templateUrl:
					'./src/components/directives/dataOperationTools/formule/formule.html',
				transclude: true,
				replace: true,
				link: function postLink(scope, element, attrs) {
					$(element).modal({
						show: false,
						keyboard: attrs.keyboard,
						backdrop: attrs.backdrop,
					});

					element.bind('keydown', function (event) {
						if (event.which == 27) {
							event.stopPropagation();
							event.stopImmediatePropagation();
							event.stopPropagation();
							if (event.target.id == element[0].id) {
								scope.vm.escConfirmation();
							} else {
								$(event.target).modal('hide');
							}
						} else {
							scope.$broadcast('keydown', event.keyCode);
						}
					});

					$(element).on('hidden.bs.modal', function (event) {
						if (event.target.id == element[0].id) {
							scope.vm.closeModal();
						}
					});
					scope.$watch(
						function () {
							return scope.vm.widgetData.widgetMenuData;
						},
						function (value) {
							if (value != undefined) {
								scope.data = value;
							}
						}
					);
					scope.$watch(
						function () {
							return scope.vm.widgetData.showFormule;
						},
						function (value) {
							scope.vm.element = element;
							if (value == true) {
								if (!scope.eventAlreadyBind) {
									scope.bindClickEventOnModal();
								}
								scope.selectedWidget = scope.vm.widgetData.widget;
								scope.isNewFormule = true;
								scope.enableCreatingNewFormula = true;
								scope.updateFormula = false;
								scope.currentFormule = {};
								scope.formule = {};
								scope.firstFunction = true;
								scope.libelle = '';
								scope.description = '';
								scope.functionSignature = '';
								scope.functionDescription = '';
								scope.lastFocused = undefined;
								scope.formuleBuilded = '';
								scope.formuleDisplayed = '';
								scope.selectedWidget.grammar.columnsTemp = angular.copy(
									scope.selectedWidget.grammar.columns
								);
								scope.onlyOneFormula = scope.vm.widgetData.onlyOneFormula;
								scope.caracMode =
									scope.vm.modes != undefined
										? scope.vm.modes.caracMode
										: false;
								scope.vm.addColumnsToElementsArray();
								scope.vm.countMyFormulasOp();
								scope.vm.refreshFormulaDisplay();
								scope.vm.returnType = scope.vm.widgetData.rootFunctionType;
								scope.vm.isReturnList = scope.vm.widgetData.isReturnList;
								scope.onlyOneFormula =
									scope.onlyOneFormula !== undefined
										? scope.onlyOneFormula
										: scope.caracMode
										? scope.caracMode
										: false;
								scope.containsAtLeastOneF = false;
								scope.search = { value: '' };
								scope.impactOn = scope.vm.widgetData.impact;
								if (scope.onlyOneFormula) {
									angular.forEach(
										scope.selectedWidget.grammar.columns,
										function (column) {
											if (column.is_formule) {
												scope.containsAtLeastOneF = true;
											}
										}
									);
								}

								//Widget menu
								scope.showWidgetMenu = scope.vm.widgetData.widgetMenuOpenAction;
								scope.data = scope.vm.widgetData.widgetMenuData;
								scope.vm.widgetData.widgetMenuData.saveAction = scope.vm.save;
								scope.menuHidden = scope.vm.widgetData.hideWidgetMenu;
								// init menu
								scope.vm.loadWidgetMenu(scope.selectedWidget);
								scope.initWidgetColumns = angular.copy(
									scope.selectedWidget.grammar.columnsTemp
								);
								scope.getFormulaCatalogCountAndShow(element);
							} else {
								$(element).modal('hide');
							}
						}
					);
				},
			};
		},
	])
	.directive('readOnly', function () {
		return {
			restrict: 'A',
			link: function ($scope, $element) {
				$element.bind('keydown', function (e) {
					e.preventDefault();
				});
			},
		};
	});
